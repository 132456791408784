import React from "react";

export const AboutIcon = () => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 10.9242C23 10.9242 19.4957 11.6805 16.2228 14.7574C12.9682 17.8467 11.5092 21.7567 11.5092 21.7567H11.4829C11.4829 21.7567 10.0239 17.8467 6.7562 14.7574C3.49903 11.6805 0 10.9242 0 10.9242V10.84C0 10.84 3.49903 10.0664 6.7562 6.99192C10.0239 3.91747 11.4829 0 11.4829 0H11.5092C11.5092 0 12.9682 3.91747 16.2228 6.99192C19.4957 10.0664 23 10.84 23 10.84V10.9242Z"
      fill="white"
    />
  </svg>
);
