import React from "react";

export const BagIcon = () => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00037 0C4.79123 0 3.00037 1.79086 3.00037 4H0V17H14V4H11.0004C11.0004 1.79086 9.20951 0 7.00037 0ZM7.00037 2C8.10494 2 9.00037 2.89543 9.00037 4H5.00037C5.00037 2.89543 5.8958 2 7.00037 2ZM12 6H2V15H12V6Z"
      fill="white"
    />
  </svg>
);

export const SearchIcon = ({ fill }) => (
  <>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="search-icon-svg desktop"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3384 6.16919C10.3384 8.47178 8.47178 10.3384 6.16919 10.3384C3.86661 10.3384 2 8.47178 2 6.16919C2 3.86661 3.86661 2 6.16919 2C8.47178 2 10.3384 3.86661 10.3384 6.16919ZM9.76609 11.1819C8.75352 11.9097 7.51141 12.3384 6.16919 12.3384C2.76204 12.3384 0 9.57635 0 6.16919C0 2.76204 2.76204 0 6.16919 0C9.57635 0 12.3384 2.76204 12.3384 6.16919C12.3384 7.51223 11.9092 8.75503 11.1806 9.76793L15.8923 14.4797L14.4781 15.8939L9.76609 11.1819Z"
        fill={fill}
      />
    </svg>

    {/* <svg
             width="13"
             height="13"
             viewBox="0 0 13 13"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             className="search-icon-svg mobile"
           >
             <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M7.75 4.875C7.75 6.46282 6.46282 7.75 4.875 7.75C3.28718 7.75 2 6.46282 2 4.875C2 3.28718 3.28718 2 4.875 2C6.46282 2 7.75 3.28718 7.75 4.875ZM7.54214 8.95635C6.77577 9.45819 5.85946 9.75 4.875 9.75C2.18261 9.75 0 7.56739 0 4.875C0 2.18261 2.18261 0 4.875 0C7.56739 0 9.75 2.18261 9.75 4.875C9.75 5.85946 9.45819 6.77577 8.95635 7.54214L12.7071 11.2929L11.2929 12.7071L7.54214 8.95635Z"
               fill={fill}
             />
           </svg> */}

    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="search-icon-svg mobile"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1875 6.09375C10.1875 8.35467 8.35467 10.1875 6.09375 10.1875C3.83283 10.1875 2 8.35467 2 6.09375C2 3.83283 3.83283 2 6.09375 2C8.35467 2 10.1875 3.83283 10.1875 6.09375ZM9.6375 11.0517C8.63898 11.7667 7.41551 12.1875 6.09375 12.1875C2.72826 12.1875 0 9.45924 0 6.09375C0 2.72826 2.72826 0 6.09375 0C9.45924 0 12.1875 2.72826 12.1875 6.09375C12.1875 7.41551 11.7667 8.63898 11.0517 9.6375L15.7071 14.2929L14.2929 15.7071L9.6375 11.0517Z"
        fill={fill}
      />
    </svg>
  </>
);

export const MenuToggleIcon = ({ fill }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.81445 7.81482V13.6296H7.81445V7.81482H13.6299V5.81482H7.81445V0H5.81445V5.81482H0.000252767L0.00025268 7.81482H5.81445Z"
      fill={fill}
    />
  </svg>
);
// <svg
//   width="12"
//   height="12"
//   viewBox="0 0 12 12"
//   fill="none"
//   xmlns="http://www.w3.org/2000/svg"
// >
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M4.75 6.75V11.5H6.75V6.75H11.5V4.75H6.75V0H4.75V4.75H0V6.75H4.75Z"
//     fill={fill}
//   />
// </svg>
