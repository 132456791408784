import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

export const FilterByCollections = ({
  setOpenFilter,
  openFilter,
  isDesktop,
  toggleLocked,
}) => {
  const data = useStaticQuery(graphql`
    {
      prismicMenu {
        data {
          collections {
            collection {
              title
              handle
              id
            }
          }
        }
      }
    }
  `);

  const content = data.prismicMenu.data.collections.map((collection, index) => (
    <li
      key={`single_collection_${index}_${collection.collection.id}`}
      className="uppercase"
    >
      <Link to={`/collections/${collection.collection.handle}/`}>
        {collection.collection.title}
      </Link>
    </li>
  ));

  return (
    <>
      {isDesktop && (
        <ol className="nav">
          <li>
            <button
              onClick={() => {
                if (openFilter === `collections`) {
                  setOpenFilter(null);
                  toggleLocked();
                } else {
                  setOpenFilter(`collections`);
                }
              }}
              className={`uppercase ${
                openFilter === `collections` ? `active` : ``
              }`}
            >
              {openFilter === `collections` ? (
                <span className="arrow">↓</span>
              ) : (
                <span className="arrow">→</span>
              )}{" "}
              collections
            </button>
          </li>

          <li>
            <button
              onClick={() => {
                if (openFilter === `filter`) {
                  setOpenFilter(null);
                  toggleLocked();
                } else {
                  setOpenFilter(`filter`);
                }
              }}
              className={`uppercase ${openFilter === `filter` ? `active` : ``}`}
            >
              {openFilter === `filter` ? (
                <span className="arrow">↓</span>
              ) : (
                <span className="arrow">→</span>
              )}{" "}
              filter
            </button>
          </li>
        </ol>
      )}

      <ol className="content">
        <li className="uppercase">
          <Link to={`/shop/`}>All Products</Link>
        </li>
        {content}
      </ol>
    </>
  );
};
