import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

// Styles
import "../styles/plyr.css";

const VideoContainer = styled.div`
  position: relative;
  z-index: 10;
`;

export const Video = ({ content }) => {
  const [player, setPlayer] = useState(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      setPlayer(
        new Plyr(`#video-${content.id}`, {
          // autoplay: false,
          // autopause: true,
          // loop: { active: true },
          // controls: ["mute", "play-large", "pause"],
          // hideControls: false,
          // clickToPlay: false,
          // playsinline: true,
          // muted: false,
          // volume: 1,
          // vimeo: {
          //   autopause: false,
          //   // background: true,
          //   byline: false,
          //   controls: false,
          // },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (player !== null) {
      player.on("playing", () => {
        setIsPlayerReady(true);
      });
    }
  }, [player]);

  if (content.primary.video.embed_url !== null) {
    return (
      <VideoContainer className="video-container">
        <div
          className="plyr__video-embed"
          id={`video-${content.id}`}
          dangerouslySetInnerHTML={{
            __html: content.primary.video.html,
          }}
        />
      </VideoContainer>
    );
  } else {
    return null;
  }
};
