import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

export const useCollections = () => {
  const data = useStaticQuery(graphql`
    {
      prismicMenu {
        data {
          collections {
            collection {
              title
              handle
              id
            }
          }
        }
      }
    }
  `);

  const collections = data.prismicMenu.data.collections.map(
    (collection, index) => (
      <li
        key={`single_collection_${index}_${collection.collection.id}`}
        className="uppercase"
      >
        <Link to={`/collections/${collection.collection.handle}/`}>
          {collection.collection.title}
        </Link>
      </li>
    )
  );

  return collections;
};
