import React, { useContext, useState, useEffect } from "react";

// Context
import { StoreContext } from "../context/store-context";

// Components
import { AddedToCartPopup } from "./added-to-cart-popup";

export const AddToCart = ({
  variantId,
  quantity,
  available,
  product,
  price,
  artistName,
  hasVariantChanged,
  isPreview,
  isOnePerCustomer,
  ...props
}) => {
  const { addVariantToCart, loading, checkout } = useContext(StoreContext);
  const [addedToCart, setAddedToCart] = useState(false);
  const [isAlreadyInCart, setIsAlreadyInCart] = useState(false);

  // Check if product is already in cart
  useEffect(() => {
    if (isOnePerCustomer && checkout?.lineItems) {
      const productInCart = checkout.lineItems.find(
        item => item.variant.product.id === product.shopifyId
      );
      setIsAlreadyInCart(!!productInCart);
    }
  }, [checkout, product.shopifyId, isOnePerCustomer]);

  const addToCart = e => {
    e.preventDefault();
    if (isOnePerCustomer && isAlreadyInCart) {
      return;
    }
    addVariantToCart(variantId, quantity);
    setAddedToCart(true);
  };

  useEffect(() => {
    if (addedToCart) {
      const closeAddToCartPopup = setTimeout(() => {
        setAddedToCart(false);
      }, 5000);
      return () => clearTimeout(closeAddToCartPopup);
    }
  }, [addedToCart]);

  const buttonText = () => {
    if (!available) return "Out of Stock";
    if (addedToCart) return "Added!";
    if (isOnePerCustomer && isAlreadyInCart) return "One per customer";
    return "Add to Bag";
  };

  return (
    <>
      <div className="product-buttons">
        <button
          disabled={
            !available ||
            loading ||
            isPreview ||
            (isOnePerCustomer && isAlreadyInCart)
          }
          onClick={e => addToCart(e)}
          {...props}
          className="uppercase"
        >
          {buttonText()}
        </button>
      </div>

      {addedToCart && (
        <AddedToCartPopup
          product={product}
          price={price}
          artistName={artistName}
        />
      )}
    </>
  );
};
