import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import { useKeyPressEvent } from "react-use";
import { GatsbyImage } from "gatsby-plugin-image";

// Slideshow
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Icons
import { CloseIcon } from "../icons/slideshow-icons";

const LightboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  background-color: #ffffff;

  z-index: 100;
  height: 100%;
  width: 100%;

  & .container {
    height: calc(${props => props.height}px - 100px);
    width: 100%;

    & .lightbox-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      & .gatsby-image-wrapper {
        height: calc(${props => props.height}px - 100px);
      }
    }
  }

  & .single-media-container {
    padding: 0 140px;

    @media (max-width: 800px) {
      padding: 0 70px;
    }

    @media (max-width: 500px) {
      padding: 0 15px;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;

    &.landscape {
    }

    &.portrait {
    }
  }
`;

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .single-media-container {
    position: relative;

    width: 100%;
    height: 100%;
  }

  & img {
    object-fit: contain !important;
    width: 100%;
    height: 100%;
  }
`;

const CloseLightbox = styled.button`
  position: fixed;
  top: 0;
  right: 0;

  z-index: 200;

  font-size: 1;
  line-height: 1;
  letter-spacing: 0;

  padding: 16px 25px;

  & svg {
    & path {
      transition: 250ms fill ease;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & svg {
        & path {
          fill: #dbaf96;
        }
      }
    }
  }

  @media (max-width: 800px) {
    padding: 15px;
  }
`;

const Arrow = styled.button`
  position: absolute;
  top: 20px;
  bottom: 20px;
  width: 50%;

  z-index: 100;

  &.previous {
    left: 0;

    cursor: url(/icons/arrow-prev@1x.png) 9 9, pointer !important;
    cursor: -webkit-image-set(
          url(/icons/arrow-prev@1x.png) 1x,
          url(/icons/arrow-prev@2x.png) 2x
        )
        9 9,
      pointer !important;
  }

  &.next {
    right: 0;

    cursor: url(/icons/arrow-next@1x.png) 9 9, pointer !important;
    cursor: -webkit-image-set(
          url(/icons/arrow-next@1x.png) 1x,
          url(/icons/arrow-next@2x.png) 2x
        )
        9 9,
      pointer !important;
  }

  & svg {
    & path {
      transition: 250ms stroke ease;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & svg {
        & path {
          stroke: #dbaf96;
        }
      }
    }
  }

  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
`;

Modal.setAppElement("#___gatsby");

export const Lightbox = ({
  productMedia,
  isLightboxOpen,
  setIsLightboxOpen,
  activeSlideNumber,
}) => {
  const media = productMedia.map((media, index) => {
    if (media.mediaContentType === "IMAGE") {
      return (
        <div
          key={`single_product_image_container_${index}_lightbox`}
          className={`single-image-container single-media-container`}
        >
          <GatsbyImage
            image={media.image.gatsbyImageData}
            alt={media.alt !== null ? media.alt : ""}
            draggable="false"
            loading="lazy"
          />
        </div>
      );
    }

    if (media.mediaContentType === "VIDEO") {
      const sources = media.sources.map((source, index) => (
        <source
          src={source.url}
          type={`video/${source.format}`}
          key={`single_video_${index}`}
        />
      ));
      return (
        <div
          key={`single_product_video_container_${index}_lightbox`}
          className={`single-video-container single-media-container`}
        >
          <video autoPlay muted loop playsInline>
            {sources}
          </video>
        </div>
      );
    }
  });

  const height = use100vh();

  // Slideshow Reference
  const slideRef = useRef();

  useEffect(() => {
    if (typeof window !== undefined) {
      document.addEventListener("keydown", event => onEscape(event));
    }
  }, [isLightboxOpen]);

  const onEscape = event => {
    if (event.keyCode === 27 && isLightboxOpen === true) {
      setIsLightboxOpen(false);
    }
  };

  // Keyboard navigation events for slideshow
  const goBack = () => {
    if (slideRef.current !== undefined && media.length > 1) {
      slideRef.current.goBack();
    }
  };

  const goNext = () => {
    if (slideRef.current !== undefined && media.length > 1) {
      slideRef.current.goNext();
    }
  };
  useKeyPressEvent("ArrowRight", goNext);
  useKeyPressEvent("ArrowLeft", goBack);

  return (
    <RemoveScroll>
      <Modal
        isOpen={isLightboxOpen}
        portalClassName={`ReactModalPortal-gallery`}
        className="artwork-lighbox-modal"
        contentLabel={`Artwork Gallery`}
        shouldFocusAfterRender={true}
      >
        <LightboxContainer height={height}>
          <CloseLightbox onClick={() => setIsLightboxOpen(false)}>
            <CloseIcon />
          </CloseLightbox>

          <div className="container">
            <div className="lightbox-wrapper">
              <GalleryContainer>
                {media.length > 1 && (
                  <Arrow
                    aria-label="Previous Slide"
                    className={`previous`}
                    onClick={() => {
                      slideRef.current.goBack();
                    }}
                  />
                )}
                <Fade
                  ref={slideRef}
                  arrows={false}
                  transitionDuration={0}
                  pauseOnHover={false}
                  autoplay={false}
                  infinite={true}
                  canSwipe={media.length > 1 ? true : false}
                  defaultIndex={activeSlideNumber}
                >
                  {media}
                </Fade>
                {media.length > 1 && (
                  <Arrow
                    aria-label="Next Slide"
                    className={`next`}
                    onClick={() => {
                      slideRef.current.goNext();
                    }}
                  />
                )}
              </GalleryContainer>
            </div>
          </div>
        </LightboxContainer>
      </Modal>
    </RemoveScroll>
  );
};

export const PreviewLightbox = ({
  productMedia,
  isLightboxOpen,
  setIsLightboxOpen,
  activeSlideNumber,
}) => {
  const media = productMedia.map((media, index) => {
    if (media.node.mediaContentType === "IMAGE") {
      return (
        <div
          key={`single_product_image_container_${index}_lightbox`}
          className={`single-image-container single-media-container`}
        >
          <img src={media.node.image.originalSrc} draggable="false" />
        </div>
      );
    }

    //  if (media.mediaContentType === "VIDEO") {
    //    const sources = media.node.sources.map((source, index) => (
    //      <source
    //        src={source.url}
    //        type={`video/${source.format}`}
    //        key={`single_video_${index}`}
    //      />
    //    ));
    //    return (
    //      <div
    //        key={`single_product_video_container_${index}_lightbox`}
    //        className={`single-video-container single-media-container`}
    //      >
    //        <video autoPlay muted loop playsInline>
    //          {sources}
    //        </video>
    //      </div>
    //    );
    //  }
  });

  const height = use100vh();

  // Slideshow Reference
  const slideRef = useRef();

  useEffect(() => {
    if (typeof window !== undefined) {
      document.addEventListener("keydown", event => onEscape(event));
    }
  }, [isLightboxOpen]);

  const onEscape = event => {
    if (event.keyCode === 27 && isLightboxOpen === true) {
      setIsLightboxOpen(false);
    }
  };

  // Keyboard navigation events for slideshow
  const goBack = () => {
    if (slideRef.current !== undefined && media.length > 1) {
      slideRef.current.goBack();
    }
  };

  const goNext = () => {
    if (slideRef.current !== undefined && media.length > 1) {
      slideRef.current.goNext();
    }
  };
  useKeyPressEvent("ArrowRight", goNext);
  useKeyPressEvent("ArrowLeft", goBack);

  return (
    <RemoveScroll>
      <Modal
        isOpen={isLightboxOpen}
        portalClassName={`ReactModalPortal-gallery`}
        className="artwork-lighbox-modal"
        contentLabel={`Artwork Gallery`}
        shouldFocusAfterRender={true}
      >
        <LightboxContainer height={height}>
          <CloseLightbox onClick={() => setIsLightboxOpen(false)}>
            <CloseIcon />
          </CloseLightbox>

          <div className="container">
            <div className="lightbox-wrapper">
              <GalleryContainer>
                {media.length > 1 && (
                  <Arrow
                    aria-label="Previous Slide"
                    className={`previous`}
                    onClick={() => {
                      slideRef.current.goBack();
                    }}
                  />
                )}
                <Fade
                  ref={slideRef}
                  arrows={false}
                  transitionDuration={0}
                  pauseOnHover={false}
                  autoplay={false}
                  infinite={true}
                  canSwipe={media.length > 1 ? true : false}
                  defaultIndex={activeSlideNumber}
                >
                  {media}
                </Fade>
                {media.length > 1 && (
                  <Arrow
                    aria-label="Next Slide"
                    className={`next`}
                    onClick={() => {
                      slideRef.current.goNext();
                    }}
                  />
                )}
              </GalleryContainer>
            </div>
          </div>
        </LightboxContainer>
      </Modal>
    </RemoveScroll>
  );
};
