import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Components
import { CloseMobileFilters } from "./close-mobile-filters";

// Filters
import { FilterByCollections } from "./filter-by-collections";
import { FilterByOption } from "./filter-by-option";

const FilterContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  transition: 300ms transform ease;

  background-color: #f5eadf;
  width: 410px;

  &.visible {
    transform: translateX(0);
    z-index: 300;
  }

  &.hidden {
    transform: translateX(-410px);
    z-index: 1;
    pointer-events: none;
  }

  & ol.nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 0 25px;

    line-height: 50px;

    & li {
      margin: 0 15px 0 0;

      & button {
        font-size: 13px;

        transition: 250ms color ease;
        color: #0b646f;

        &.active {
          color: #e9ccb2;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #e9ccb2;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  cursor: pointer;

  &.visible {
    z-index: 100;
  }

  &.hidden {
    z-index: -100;
    pointer-events: none;
  }
`;

const CollectionsContainer = styled.div`
  & ol.content {
    display: block;
    padding: 30px;

    overflow-y: scroll;
    height: calc(${props => props.height}px - 80px);
    min-height: calc(${props => props.height}px - 80px);

    & li {
      margin: 0;
      /* line-height: 32px; */
      line-height: 26px;

      & a {
        color: #0b646f;

        /* font-size: 22px;
        line-height: 32px; */

        font-size: 18px;
        line-height: 26px;

        &:hover,
        &.current {
          color: #e9ccb2;
        }

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    & ol.content {
      padding: 10px;
    }
  }
`;

const OptionsContainer = styled.div`
  & .content {
    display: block;
    padding: 30px;

    overflow-y: scroll;
    height: calc(${props => props.height}px - 80px);
    min-height: calc(${props => props.height}px - 80px);

    & h3 {
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.02em;

      color: #0b646f;
    }

    & .option-container {
      margin: 0 0 20px 0;
    }

    & ol {
      & li {
        margin: 0;
        line-height: 21px;

        &.active {
          & button {
            color: #e9ccb2;
          }
        }

        & button {
          font-size: 15px;
          line-height: 21px;

          color: #0b646f;
          text-align: left;

          &.active {
            color: #e9ccb2;
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #e9ccb2;
            }
          }

          @media (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  & .apply-filter-button-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: grid;
    grid-template-columns: 1fr 30px 1fr;
    align-items: center;

    height: 80px;
    background-color: #f5eadf;

    & button {
      font-size: 10px;
      line-height: 31px;

      text-align: center;
      letter-spacing: 0.02em;

      color: #0b646f;

      width: fit-content;

      &:first-of-type {
        margin: 0 0 0 auto;
      }

      &:last-of-type {
        margin: 0 auto 0 0;
      }

      display: block;

      min-width: 150px;
      height: 30px;
      line-height: 31px;

      padding: 0 10px;
      background-color: #fff;

      @media (hover: hover) and (pointer: fine) {
        transition: 250ms color ease, 250ms background-color ease;

        &:hover {
          color: #fff;
          background-color: #0b646f;
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .content {
      padding: 10px;
    }
  }
`;

export const FilterWrapper = ({
  open,
  openFilter,
  setOpenFilter,
  pageSetup,
  setPageSetup,
  isDesktop,
  toggleLocked,
  location,
}) => {
  const [sortOption, setSortOption] = useState(null);
  const [priceOption, setPriceOption] = useState(null);
  const [artistOption, setArtistOption] = useState([]);

  const height = use100vh();

  useEffect(() => {
    setSortOption(null);
    setPriceOption(null);
    setArtistOption([]);
  }, [location]);

  return (
    <>
      <FilterContainer className={open ? `visible` : `hidden`}>
        {!isDesktop && <CloseMobileFilters setOpenFilter={setOpenFilter} />}

        {openFilter === `collections` && (
          <CollectionsContainer height={height}>
            <FilterByCollections
              open={openFilter === `collections`}
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              setPageSetup={setPageSetup}
              isDesktop={isDesktop}
              toggleLocked={toggleLocked}
            />
          </CollectionsContainer>
        )}

        {openFilter === `filter` && (
          <OptionsContainer height={height}>
            <FilterByOption
              open={openFilter === `filter`}
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              pageSetup={pageSetup}
              setPageSetup={setPageSetup}
              isDesktop={isDesktop}
              sortOption={sortOption}
              setSortOption={setSortOption}
              priceOption={priceOption}
              setPriceOption={setPriceOption}
              artistOption={artistOption}
              setArtistOption={setArtistOption}
              toggleLocked={toggleLocked}
            />
          </OptionsContainer>
        )}
      </FilterContainer>

      <Background
        className={open ? `visible` : `hidden`}
        onClick={() => {
          setOpenFilter(null);
          toggleLocked(false);
        }}
      />
    </>
  );
};
