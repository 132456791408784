import React from "react";
import styled from "styled-components";
import { useSelect } from "downshift";

// Components
import { DropdownIcon } from "../icons/dropdown-icon";

const DropDownContainer = styled.div`
  position: relative;

  width: 400px;

  /* font-size: 12px; */
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #0b646f;

  & button[aria-expanded="true"] + ul {
    border: 1px solid #000;
    padding: 10px 0;
  }

  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 17px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const DropDownHeader = styled.button`
  display: grid;
  grid-template-columns: auto 10px;
  grid-column-gap: 10px;
  align-items: center;

  min-width: 115px;

  color: #0b646f;
  text-align: left;

  padding: 0 0 10px 0;
  border-bottom: 1px solid #f5eadf;

  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 17px;
  }
`;

const DropDownList = styled.ol`
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;

  z-index: 1;

  max-height: 140px;
  width: 100%;
  overflow-y: auto;

  background-color: #fff;

  &.open {
    border: 1px solid #0b646f;
  }

  list-style: none;

  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 17px;
  }
`;

const DropDownListItem = styled.li`
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.02em;

  padding: 5px 10px;

  background: ${props => (props.ishighlighted ? "#0b646f" : "#fff")};
  cursor: pointer;
  color: ${props => (props.ishighlighted ? "#fff" : "#0b646f")};

  text-align: left;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 26px;
  }
`;

export const VariantSelectors = ({
  handleOptionChange,
  items,
  name,
  index,
}) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    onSelectedItemChange: ({ selectedItem }) => {
      handleOptionChange(index, selectedItem);
    },
  });

  return (
    <DropDownContainer>
      <DropDownHeader
        {...getToggleButtonProps()}
        type="button"
        aria-label={`Select a ${name}`}
      >
        {(selectedItem && selectedItem) || name}
        <DropdownIcon isOpen={isOpen} />
      </DropDownHeader>

      <DropDownList
        {...getMenuProps()}
        aria-label={`Select a ${name}`}
        className={isOpen ? `open` : `closed`}
      >
        {isOpen &&
          items.map((value, index) => (
            <DropDownListItem
              ishighlighted={highlightedIndex === index}
              key={`${name}-${value}`}
              {...getItemProps({ value, index })}
            >
              {value}
            </DropDownListItem>
          ))}
      </DropDownList>
    </DropDownContainer>
  );
};
