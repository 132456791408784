import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

// Icons
import { AddedToCartTriangle } from "../icons/cart-icons";

const AddedToCartPopupContainer = styled.div`
  position: fixed;
  width: 410px;
  height: auto;

  right: 0;
  top: 50px;

  background: #f5eadf;
  color: #0b646f;

  padding: 20px;
  z-index: 300;

  & svg {
    position: absolute;
    top: -12px;
    right: 47px;
  }

  & img {
    object-fit: contain !important;
  }

  & .inner-popup-container {
    display: grid;
    grid-template-columns: 100px auto;
    grid-column-gap: 50px;

    & p {
      margin: 0;
    }

    & .column-left {
      padding: 10px;
      background: #fff;
      height: fit-content;

      & .gatsby-image-wrapper {
        background-color: #fff;
      }
    }

    & .popup-title {
      margin: 0 0 1em 0;

      & p {
        font-size: 10px;
        line-height: 20px;
      }
    }

    & .product-details {
      & p {
        /* font-size: 15px;
        line-height: 20px; */

        font-size: 12px;
        line-height: 16px;
      }
    }

    & .bag-link {
      & a {
        font-size: 10px;
        line-height: 32px;

        text-align: center;
        letter-spacing: 0.02em;

        color: #0b646f;

        display: block;
        width: 120px;
        height: 30px;
        margin: 30px 0 0 0;

        background: #fff;

        transition: 250ms background-color ease, 250ms color ease;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #fff;
            background-color: #0b646f;
          }
        }
      }
    }
  }

  @media (max-width: 960px) {
    top: 30px;

    & svg {
      top: -4px;

      right: 10px;
      width: 10px;
      height: auto;
    }
  }

  @media (max-width: 768px) {
    padding: 10px;

    & .inner-popup-container {
      grid-column-gap: 20px;

      & .product-details {
        & p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const AddedToCartPopup = ({ product, price, artistName, prismic }) => {
  return (
    <AddedToCartPopupContainer>
      <AddedToCartTriangle />
      <div className="inner-popup-container">
        <div className="column-left">
          {prismic ? (
            <img
              src={product.product.image.src}
              alt={product.product.title}
              draggable="false"
              loading="lazy"
            />
          ) : (
            <GatsbyImage
              image={product.featuredImage.gatsbyImageData}
              alt={product.title}
              draggable="false"
              loading="lazy"
            />
          )}
        </div>

        <div className="column-right">
          <div className="popup-title">
            <p className="uppercase">Added to Bag</p>
          </div>

          <div className="product-details">
            <p>{artistName}</p>
            {prismic ? <p>{product.product.title}</p> : <p>{product.title}</p>}
            <p>{price}</p>
          </div>

          <div className="bag-link">
            <Link to={`/cart/`} className="uppercase">
              View Bag
            </Link>
          </div>
        </div>
      </div>
    </AddedToCartPopupContainer>
  );
};
