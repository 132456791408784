import React from "react";
import styled from "styled-components";

// Slideshow
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .single-media-container {
    position: relative;

    width: 100%;
    height: 100%;
  }

  & img {
    object-fit: contain !important;
    width: 100%;
    height: 100%;
  }

  & .react-slideshow-fade-images-wrap {
    align-items: center;
  }
`;

export const Gallery = ({ media, autoplay }) => {
  return (
    <GalleryContainer>
      <Fade
        arrows={false}
        transitionDuration={0}
        pauseOnHover={false}
        autoplay={autoplay}
        infinite={true}
        canSwipe={media.length > 1 ? true : false}
        defaultIndex={0}
      >
        {media}
      </Fade>
    </GalleryContainer>
  );
};
