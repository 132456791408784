import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  cursor: pointer;
  transform: rotate(${props => (props.isOpen ? "-180deg" : "0")});

  @media (max-width: 600px) {
    width: 12px;
    height: auto;

    & path {
      stroke-width: 1.25px;
    }
  }
`;

export const DropdownIcon = ({ isOpen }) => (
  <Icon
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    isOpen={isOpen}
  >
    <path d="M1 1L5 5L9 1" stroke="#0B646F" strokeWidth="1.5" />
  </Icon>
);
