import React, { useEffect } from "react";

export const FilterByOption = ({
  setOpenFilter,
  toggleLocked,
  openFilter,
  pageSetup,
  setPageSetup,
  isDesktop,
  sortOption,
  setSortOption,
  priceOption,
  setPriceOption,
  artistOption,
  setArtistOption,
}) => {
  useEffect(() => {
    if (sortOption !== null) {
      setPageSetup({
        ...pageSetup,
        sortOption: sortOption,
      });
    }
  }, [sortOption]);

  useEffect(() => {
    if (priceOption !== null) {
      setPageSetup({
        ...pageSetup,
        priceOption: priceOption,
      });
    }
  }, [priceOption]);

  // useEffect(() => {
  //   if (artistOption.length >= 1) {
  //     setPageSetup({
  //       ...pageSetup,
  //       artistOption: [...pageSetup.artistOption, artistOption],
  //     });
  //   }
  // }, [artistOption]);

  const artists = pageSetup.filterArtists.map((artist, index) => (
    <li key={`single_filter_artist_${index}`}>
      <button
        onClick={() => {
          if (pageSetup.artistOption.length >= 1) {
            if (pageSetup.artistOption.includes(artist)) {
              const filteredArtists = pageSetup.artistOption.filter(
                existingArtist => existingArtist !== artist
              );

              // if (filteredArtists.length > 1) {
              setArtistOption(...filteredArtists);

              setPageSetup({
                ...pageSetup,
                artistOption: [...filteredArtists],
              });
              // } else {
              //   setArtistOption([]);

              //   setPageSetup({
              //     ...pageSetup,
              //     artistOption: [],
              //   });
              // }
            } else {
              setArtistOption(artist);
              setPageSetup({
                ...pageSetup,
                artistOption: [...pageSetup.artistOption, artist],
              });
            }
          } else {
            setArtistOption(artist);
            setPageSetup({
              ...pageSetup,
              artistOption: [artist],
            });
          }
        }}
        className={
          pageSetup.artistOption.length >= 1
            ? pageSetup.artistOption.includes(artist)
              ? `active`
              : ``
            : ``
        }
      >
        {artist}
      </button>
    </li>
  ));

  return (
    <>
      {isDesktop && (
        <ol className="nav">
          <li>
            <button
              onClick={() => {
                if (openFilter === `collections`) {
                  setOpenFilter(null);
                  toggleLocked();
                } else {
                  setOpenFilter(`collections`);
                }
              }}
              className={`uppercase ${
                openFilter === `collections` ? `active` : ``
              }`}
            >
              {openFilter === `collections` ? (
                <span className="arrow">↓</span>
              ) : (
                <span className="arrow">→</span>
              )}{" "}
              collections
            </button>
          </li>

          <li>
            <button
              onClick={() => {
                if (openFilter === `filter`) {
                  setOpenFilter(null);
                  toggleLocked();
                } else {
                  setOpenFilter(`filter`);
                }
              }}
              className={`uppercase ${openFilter === `filter` ? `active` : ``}`}
            >
              {openFilter === `filter` ? (
                <span className="arrow">↓</span>
              ) : (
                <span className="arrow">→</span>
              )}{" "}
              filter
            </button>
          </li>
        </ol>
      )}

      <div className="content">
        <div className="option-container">
          <h3 className="news-gothic">Sort</h3>
          <ol>
            <li>
              <button
                onClick={() => setSortOption(`newest`)}
                className={`uppercase ${
                  sortOption === `newest` ? `active` : ``
                }`}
              >
                Newest
              </button>
            </li>
            <li>
              <button
                onClick={() => setSortOption(`ascending`)}
                className={`uppercase ${
                  sortOption === `ascending` ? `active` : ``
                }`}
              >
                Price Ascending
              </button>
            </li>
            <li>
              <button
                onClick={() => setSortOption(`descending`)}
                className={`uppercase ${
                  sortOption === `descending` ? `active` : ``
                }`}
              >
                Price Descending
              </button>
            </li>
            <li>
              <button
                onClick={() => setSortOption(`a-z`)}
                className={`uppercase ${sortOption === `a-z` ? `active` : ``}`}
              >
                A–Z by Artist & Designer
              </button>
            </li>
          </ol>
        </div>
        <div className="option-container">
          <h3 className="news-gothic">Price</h3>
          <ol>
            <li>
              <button
                onClick={() => setPriceOption({ direction: `less`, price: 50 })}
                className={`uppercase ${
                  priceOption !== null
                    ? priceOption.price === 50
                      ? `active`
                      : ``
                    : ``
                }`}
              >
                Under £50
              </button>
            </li>
            <li>
              <button
                onClick={() =>
                  setPriceOption({ direction: `less`, price: 100 })
                }
                className={`uppercase ${
                  priceOption !== null
                    ? priceOption.price === 100
                      ? `active`
                      : ``
                    : ``
                }`}
              >
                Under £100
              </button>
            </li>
            <li>
              <button
                onClick={() =>
                  setPriceOption({ direction: `less`, price: 250 })
                }
                className={`uppercase ${
                  priceOption !== null
                    ? priceOption.price === 250
                      ? `active`
                      : ``
                    : ``
                }`}
              >
                Under £250
              </button>
            </li>
            <li>
              <button
                onClick={() =>
                  setPriceOption({ direction: `less`, price: 500 })
                }
                className={`uppercase ${
                  priceOption !== null
                    ? priceOption.price === 500
                      ? `active`
                      : ``
                    : ``
                }`}
              >
                Under £500
              </button>
            </li>
            <li>
              <button
                onClick={() =>
                  setPriceOption({ direction: `less`, price: 1000 })
                }
                className={`uppercase ${
                  priceOption !== null
                    ? priceOption.price === 1000 &&
                      priceOption.direction === `less`
                      ? `active`
                      : ``
                    : ``
                }`}
              >
                Under £1,000
              </button>
            </li>
            <li>
              <button
                onClick={() =>
                  setPriceOption({ direction: `greater`, price: 1000 })
                }
                className={`uppercase ${
                  priceOption !== null
                    ? priceOption.price === 1000 &&
                      priceOption.direction === `greater`
                      ? `active`
                      : ``
                    : ``
                }`}
              >
                Over £1,000
              </button>
            </li>
          </ol>
        </div>

        <div className="option-container">
          <h3 className="news-gothic">Artist & Designer</h3>
          <ol>{artists}</ol>
        </div>

        <div className="apply-filter-button-container">
          <button
            className="uppercase"
            onClick={() => {
              setOpenFilter(null);
              toggleLocked();

              setPageSetup({
                ...pageSetup,
                applyFilter: true,
              });
            }}
          >
            Apply Filter
          </button>
          <div className="spacer" />
          <button
            className="uppercase"
            onClick={() => {
              setSortOption(null);
              setPriceOption(null);
              setArtistOption([]);

              setPageSetup({
                ...pageSetup,
                sortOption: null,
                priceOption: null,
                artistOption: [],
                applyFilter: true,
              });
            }}
          >
            Clear Filter
          </button>
        </div>
      </div>
    </>
  );
};
