import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Hooks
import { useFooterLinks } from "../hooks/useFooterLinks";

// Icons
import { InstagramIcon, FacebookIcon } from "../icons/social-icons";

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;

  padding: 0 25px;

  line-height: 40px;

  & ol {
    padding: 0;
    margin: 0;

    &.right-column {
      margin: 0 62px 0 0;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & li {
      margin: 0 15px 0 0;

      &:last-of-type {
        margin: 0;
      }

      & a,
      & button {
        font-size: 12px;
        line-height: 40px;

        transition: 250ms color ease;
        display: block;

        & svg {
          display: block;
          line-height: 40px;

          & path {
            transition: 250ms fill ease;
          }
        }

        &.current {
          color: #e9ccb2;

          & svg {
            & path {
              fill: #e9ccb2;
            }
          }
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #e9ccb2;

            & svg {
              & path {
                fill: #e9ccb2;
              }
            }
          }
        }
      }
    }
  }
`;

export const Footer = () => {
  const content = useFooterLinks();

  return (
    <>
      <FooterWrapper>
        <ol className="left-column">{content}</ol>

        <ol className="right-column">
          <li>
            <Link to={`/mailing-list/`} state={{ from: `website` }}>
              Join our mailing list
            </Link>
          </li>

          <li>
            <a
              href="https://www.instagram.com/houseofvoltaire/"
              target="_blank"
              aria-label="Instagram"
            >
              <InstagramIcon />
            </a>
          </li>

          <li>
            <a
              href="https://www.facebook.com/House-of-Voltaire-170830762933255/"
              target="_blank"
              aria-label="Facebook"
            >
              <FacebookIcon />
            </a>
          </li>
        </ol>
      </FooterWrapper>
    </>
  );
};
