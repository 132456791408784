import React, { forwardRef, useState, useEffect } from "react";

//https://www.alltimepower.com/blog/react-hook-forms-custom-checkbox/
export const Checkbox = forwardRef(
  (
    { label, name, value, onChange, defaultChecked, id, ...rest },
    forwardedRef
  ) => {
    const [checked, setChecked] = useState(defaultChecked);

    useEffect(() => {
      if (onChange) {
        onChange(checked);
      }
    }, [checked]);

    return (
      <>
        <div
          className="mailchimp-group-toggle"
          onClick={() => setChecked(!checked)}
        >
          <input
            ref={forwardedRef}
            type="checkbox"
            name={name}
            id={id}
            value={value}
            checked={checked}
            onChange={e => {
              setChecked(e.target.checked);
            }}
          />
          <label htmlFor={name} />
        </div>
      </>
    );
  }
);
