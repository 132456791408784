import React from "react";

export const InstagramIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="instagram-icon"
  >
    <path
      d="M13.0003 6.50153C13.0003 7.80387 13.0017 9.1062 13.0003 10.4085C12.998 11.9109 11.9083 12.9985 10.4013 12.9994C7.80463 13.0013 5.20841 13.0013 2.61173 12.9994C1.09279 12.999 0.00310223 11.9109 0.00172346 10.3934C-0.000574486 7.80387 -0.000574486 5.21436 0.00172346 2.62485C0.00310223 1.07566 1.08452 0.0013301 2.63839 0.000870397C5.23506 -4.90085e-05 7.83128 -0.000508711 10.428 0.000870397C11.9005 0.0017898 12.9971 1.09772 12.9998 2.57107C13.0021 3.88122 13.0003 5.19183 13.0003 6.50153ZM1.26743 5.17666C1.26743 5.25895 1.26743 5.33342 1.26743 5.40835C1.26743 7.07064 1.26651 8.73293 1.26789 10.3952C1.26881 11.2319 1.77344 11.7329 2.61724 11.7334C5.20565 11.7348 7.79452 11.7348 10.3829 11.7334C11.2318 11.7334 11.7424 11.2254 11.7438 10.3764C11.7465 8.72189 11.7447 7.06696 11.7438 5.41249C11.7438 5.33158 11.7341 5.25068 11.7295 5.17483H9.82498C10.3457 6.89319 9.98997 8.35137 8.5225 9.40133C7.32665 10.2573 5.99522 10.3226 4.73825 9.56039C3.06626 8.54675 2.63333 7.01685 3.18162 5.15506C2.59427 5.15506 2.02116 5.1546 1.44805 5.15598C1.39612 5.15598 1.3451 5.16747 1.26743 5.17666ZM6.48331 4.20715C5.20014 4.22692 4.19639 5.25987 4.21432 6.54106C4.23224 7.80616 5.26586 8.80188 6.54443 8.78579C7.80555 8.77016 8.81434 7.72342 8.79182 6.45556C8.7693 5.20057 7.72971 4.1883 6.48331 4.20715ZM9.30702 3.69136H11.4905V1.51237H9.30702V3.69136Z"
      fill="#0B646F"
    />
    {/* <path
      d="M1.26859 5.1763C1.3458 5.1671 1.39773 5.15607 1.44967 5.15561C2.02277 5.15423 2.59588 5.15469 3.18324 5.15469C2.63495 7.01649 3.06742 8.54592 4.73987 9.56002C5.99684 10.3222 7.32827 10.2569 8.52412 9.40097C9.99205 8.351 10.3473 6.89283 9.8266 5.17446H11.7311C11.7357 5.25031 11.7454 5.33122 11.7454 5.41212C11.7463 7.06659 11.7481 8.72153 11.7454 10.376C11.744 11.2251 11.2329 11.7326 10.3845 11.733C7.79613 11.7344 5.20727 11.7344 2.61886 11.733C1.77506 11.733 1.27043 11.2315 1.26951 10.3948C1.26767 8.73256 1.26905 7.07027 1.26905 5.40799C1.26905 5.33351 1.26859 5.25858 1.26859 5.1763Z"
      fill="white"
    />
    <path
      d="M6.48331 4.20715C7.72971 4.1883 8.7693 5.20057 8.79182 6.45556C8.81434 7.72342 7.80555 8.77016 6.54443 8.78579C5.26586 8.80188 4.23224 7.80616 4.21432 6.54106C4.19639 5.25987 5.20014 4.22692 6.48331 4.20715Z"
      fill="white"
    />
    <path d="M9.30702 3.69136V1.51237H11.4905V3.69136H9.30702Z" fill="white" /> */}
  </svg>
);

export const FacebookIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="facebook-icon"
  >
    <path
      d="M8.72155 13V8.55846H10.1726C10.2333 7.99849 10.2929 7.44768 10.3542 6.87988H8.74178C8.74178 6.68603 8.74178 6.50755 8.74178 6.32907C8.74178 6.0999 8.74151 5.87074 8.74178 5.6413C8.74259 5.29997 9.03048 5.00718 9.37584 5.00259C9.67264 4.99855 9.96943 5.00179 10.2662 5.00179C10.3148 5.00179 10.3636 5.00179 10.4219 5.00179V3.41756H10.2687C9.85045 3.41756 9.43224 3.41756 9.01402 3.41756C7.89619 3.41756 7.08324 4.13257 6.96048 5.24255C6.92594 5.55394 6.93565 5.8702 6.92972 6.18456C6.9254 6.41777 6.92891 6.65125 6.92891 6.9028H5.47866V8.65579H6.91946V12.9922C6.87845 12.9943 6.83528 12.9987 6.79211 12.9987C5.60925 12.9976 4.42666 12.9981 3.2438 12.9938C2.77459 12.9922 2.30592 12.959 1.8637 12.7905C0.877533 12.4149 0.272612 11.7053 0.0848215 10.6657C0.0238437 10.3276 0.00387752 9.97796 0.00279827 9.6334C-0.002598 7.5388 0.000909576 5.44421 0.00414734 3.34962C0.00495678 2.81822 0.0235739 2.28548 0.23187 1.78643C0.649541 0.785644 1.40529 0.208411 2.47699 0.059048C2.79618 0.0145625 3.12211 0.00431737 3.44508 0.00350855C5.48244 -0.00134441 7.5198 -0.000535585 9.55689 0.0021605C10.0922 0.00296933 10.6321 0.00890073 11.1396 0.205715C12.1932 0.614172 12.7906 1.39226 12.9457 2.5071C12.9754 2.71982 12.9827 2.93685 12.9835 3.152C12.9913 5.3051 12.9967 7.45792 13 9.61102C13.0008 10.1287 12.9811 10.6479 12.7925 11.1378C12.4156 12.116 11.7087 12.7145 10.6758 12.9118C10.0315 13.035 9.37854 12.9814 8.72182 13H8.72155Z"
      fill="#0B646F"
    />
  </svg>
);
