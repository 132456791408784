import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Icons
import { MenuToggleIcon } from "../icons/menu-icons";

// Search
import SearchIndex from "../search/search-index";

// Filters
import { FilterByCollections } from "./filter-by-collections";
import { FilterByOption } from "./filter-by-option";

const MobileFiltersContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 400;

  &.visible {
    background-color: #f5eadf;
    height: 100%;
  }

  & .filter-navigation {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: grid;
    grid-template-columns: 1fr 2px 1fr;

    background-color: #fff;

    z-index: 500;
    height: 60px;

    & button {
      height: 60px;
      width: 100%;

      background-color: #f5eadf;
      color: #0b646f;

      position: relative;
      line-height: 61px;

      z-index: 10;

      transition: 250ms background-color ease, 250ms color ease;

      & .closed-arrow {
        display: inline-block;
      }
      & .open-arrow {
        display: none;
      }

      &.active {
        background-color: #fff;
        color: #debb9b;

        & .closed-arrow {
          display: none;
        }
        & .open-arrow {
          display: inline-block;
        }
      }
    }
  }

  & .mobile-menu-navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    height: 52px;
    padding: 0 10px;

    & button {
      &.menu-toggle-is-open {
        & svg {
          display: block;

          transform: rotate(45deg);
        }
      }
    }

    & svg {
      &.search-icon-svg {
        display: block;
        margin: 0;
        padding: 2px 0 0 0;

        &.desktop {
          display: none;
        }
      }
    }
  }
`;

const CollectionsContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transition: 300ms transform ease;
  padding: 10px 0;

  &.visible {
    transform: translateY(0);
    z-index: 300;
  }

  &.hidden {
    transform: translateY(100%);
    z-index: 1;
    user-select: none;
  }

  background-color: #f5eadf;

  & ol.content {
    display: block;
    padding: 30px;

    overflow: scroll;
    min-height: calc(${props => props.height}px - 90px);
    height: calc(${props => props.height}px - 90px);

    & li {
      margin: 0;
      line-height: 24px;

      & a {
        color: #0b646f;

        font-size: 22px;
        line-height: 24px;

        &.current {
          color: #e9ccb2;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #e9ccb2;
          }
        }

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    & ol.content {
      padding: 0 10px 10px 10px;
    }
  }
`;

const OptionsContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transition: 300ms transform ease;
  padding: 10px 0;

  background-color: #f5eadf;

  &.visible {
    transform: translateY(0);
    z-index: 300;
    overflow-y: scroll;
  }

  &.hidden {
    transform: translateY(100%);
    z-index: 1;
    user-select: none;
  }

  & .content {
    display: block;
    position: relative;
    z-index: 1;

    padding: 30px;

    overflow-y: scroll;
    min-height: calc(${props => props.height}px - 107px);
    height: calc(${props => props.height}px - 107px);

    & h3 {
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.02em;

      color: #0b646f;
    }

    & .option-container {
      position: relative;
      z-index: 1;
      margin: 0 0 35px 0;
    }

    & ol {
      & li {
        margin: 0;
        line-height: 21px;

        &.active {
          & button {
            color: #e9ccb2;
          }
        }

        & button {
          font-size: 15px;
          line-height: 21px;

          color: #0b646f;
          text-align: left;

          &.active {
            color: #e9ccb2;
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #e9ccb2;
            }
          }

          @media (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  & .apply-filter-button-container {
    position: fixed;
    bottom: 60px;
    left: 0;
    right: 0;
    z-index: 100;

    padding: 0 0 2px 0;
    background-color: #f5eadf;

    display: grid;
    grid-template-columns: 1fr 2px 1fr;
    align-items: center;

    & button {
      /* font-size: 10px; */
      line-height: 31px;

      text-align: center;
      letter-spacing: 0.02em;

      color: #0b646f;
      display: block;

      height: 30px;
      line-height: 31px;

      padding: 0 10px;
      background-color: #fff;

      @media (hover: hover) and (pointer: fine) {
        transition: 250ms color ease, 250ms background-color ease;

        &:hover {
          color: #fff;
          background-color: #0b646f;
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .content {
      padding: 0;

      & .option-container {
        padding: 0 10px;

        &:nth-of-type(3) {
          margin: 0 0 82px 0;
        }
      }

      & .apply-filter-button-container {
        & button {
          height: 45px;
          line-height: 46px;
        }
      }
    }
  }
`;

const Navigation = ({
  setOpenFilter,
  isDesktop,
  location,
  isSearchOpen,
  setIsSearchOpen,
}) => (
  <div className="mobile-menu-navigation">
    <button onClick={() => setOpenFilter(null)} className="menu-toggle-is-open">
      <MenuToggleIcon fill={`#0B646F`} />
    </button>

    <SearchIndex
      isSearchOpen={isSearchOpen}
      setIsSearchOpen={setIsSearchOpen}
      location={location}
      fill={`#0B646F`}
      isDesktop={isDesktop}
    />
  </div>
);

export const MobileFilters = ({
  location,
  pageSetup,
  setPageSetup,
  isDesktop,
}) => {
  const [sortOption, setSortOption] = useState(null);
  const [priceOption, setPriceOption] = useState(null);
  const [artistOption, setArtistOption] = useState([]);
  const toggleLocked = () => {};

  const height = use100vh();

  useEffect(() => {
    setSortOption(null);
    setPriceOption(null);
    setArtistOption([]);
  }, [location]);

  // Filters
  const [openFilter, setOpenFilter] = useState(null);

  // Search
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <>
      <MobileFiltersContainer
        className={openFilter === null ? `hidden` : `visible`}
        height={height}
      >
        <div className="filter-navigation">
          <button
            onClick={() => {
              if (openFilter === `collections`) {
                setOpenFilter(null);
              } else {
                setOpenFilter(`collections`);
              }
            }}
            className={`uppercase ${
              openFilter === `collections` ? `active` : ``
            }`}
          >
            <span className="closed-arrow">→</span>
            <span className="open-arrow">↑</span> collections
          </button>
          <div className="spacer" />
          <button
            onClick={() => {
              if (openFilter === `filter`) {
                setOpenFilter(null);
              } else {
                setOpenFilter(`filter`);
              }
            }}
            className={`uppercase ${openFilter === `filter` ? `active` : ``}`}
          >
            <span className="closed-arrow">→</span>
            <span className="open-arrow">↑</span> filter
          </button>
        </div>

        <CollectionsContainer
          className={openFilter === `collections` ? `visible` : `hidden`}
          height={height}
        >
          <Navigation
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            setPageSetup={setPageSetup}
            isDesktop={isDesktop}
            location={location}
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
          />
          <FilterByCollections
            open={openFilter === `collections`}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            setPageSetup={setPageSetup}
            isDesktop={isDesktop}
          />
        </CollectionsContainer>

        <OptionsContainer
          className={openFilter === `filter` ? `visible` : `hidden`}
          height={height}
        >
          <Navigation
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            setPageSetup={setPageSetup}
            isDesktop={isDesktop}
            location={location}
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
            toggleLocked={toggleLocked}
          />
          <FilterByOption
            open={openFilter === `filter`}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            pageSetup={pageSetup}
            setPageSetup={setPageSetup}
            isDesktop={isDesktop}
            sortOption={sortOption}
            setSortOption={setSortOption}
            priceOption={priceOption}
            setPriceOption={setPriceOption}
            artistOption={artistOption}
            setArtistOption={setArtistOption}
            toggleLocked={toggleLocked}
          />
        </OptionsContainer>
      </MobileFiltersContainer>
    </>
  );
};
