import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useAsyncFn } from "react-use";

// Components
import { Header } from "./header";
import { Footer } from "./footer";
import { Cookies } from "../cookies/cookies";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Context
import { useDraftProductsContext } from "../context/draft-products";

const Main = styled.main`
  min-height: calc(${props => props.height}px - 50px - 40px);
`;

export const App = ({
  location,
  children,
  pageSetup,
  setPageSetup,
  height,
}) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 961px)");

  const { setSessionStorageDraftProducts } = useDraftProductsContext();

  // Using useAsyncFn for better control over loading and error states
  const [fetchState, fetchDraftProducts] = useAsyncFn(async () => {
    const response = await fetch(`/.netlify/functions/get-all-draft-products`, {
      method: "GET", // Changed to GET as per the updated function
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch draft products");
    }

    const data = await response.json();
    return data;
  }, []);

  // Memoized function to handle the fetching logic
  const handleFetchDraftProducts = useCallback(async () => {
    try {
      const result = await fetchDraftProducts();

      if (result?.products) {
        setSessionStorageDraftProducts(result.products);

        // If data was from cache, schedule next update
        if (result.cached) {
          const cacheAge = new Date() - new Date(result.cachedAt);
          const remainingCacheTime = 5 * 60 * 1000 - cacheAge; // 5 minutes minus cache age

          if (remainingCacheTime > 0) {
            setTimeout(() => handleFetchDraftProducts(), remainingCacheTime);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching draft products:", error);

      // Handle rate limiting
      if (error.message?.includes("rate limit")) {
        const retryAfter = parseInt(error.headers?.["retry-after"] || "60");
        setTimeout(() => handleFetchDraftProducts(), retryAfter * 1000);
      }
    }
  }, [fetchDraftProducts, setSessionStorageDraftProducts]);

  // Effect to fetch draft products
  useEffect(() => {
    handleFetchDraftProducts();

    // Cleanup function
    return () => {
      // Clean up any pending timeouts if component unmounts
    };
  }, [handleFetchDraftProducts]);

  return (
    <>
      <Helmet
        meta={[
          {
            name: "viewport",
            content:
              "width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1",
          },
          {
            name: "facebook-domain-verification",
            content: "qduqjt2j96c918931sthmcr3sqh0m0",
          },
          {
            name: "google-site-verification",
            content: "4VqnUHS8jF8V-dRtpCSHi-0uj2OqRSpotnKa32OMw2E",
          },
        ]}
      />
      {pageSetup.pageType !== `delivery` && (
        <Header
          location={location}
          pageSetup={pageSetup}
          setPageSetup={setPageSetup}
          isDesktop={isDesktop}
        />
      )}

      <Main height={height}>{children}</Main>

      {pageSetup.pageType !== `delivery` && isDesktop && (
        <Footer location={location} pageSetup={pageSetup} />
      )}

      <Cookies location={location} />
    </>
  );
};
