import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { use100vh } from "react-div-100vh";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { PageSetup } from "../components/context/page-context";

// Icons
import { AboutIcon } from "../components/icons/about-icon";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 110px 20px 75px 20px;

  max-width: 915px;
  margin: 0 auto;

  min-height: ${props => props.height}px;

  @media (max-width: 960px) {
    padding: 30px 20px;
  }

  @media (max-width: 768px) {
    padding: 30px 10px;
  }

  & .introduction-section,
  & .funders-section {
    & .gatsby-image-wrapper {
      display: block;

      max-width: 370px;
      margin: 0 auto;

      & img {
        object-fit: contain !important;
      }

      @media (max-width: 768px) {
        max-width: 200px;
      }
    }

    @media (max-width: 768px) {
      padding: 0 10px;
    }

    & .title-container {
      margin: 50px auto;

      & h2 {
        text-align: center;
        text-transform: uppercase;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    & .text-container,
    & .about-text-container {
      max-width: 785px;

      /* max-width: 750px; */
      margin: 50px auto 40px auto;

      & p {
        font-size: 16px;
        line-height: 23px;

        /* font-size: 14px;
        line-height: 21px; */
      }

      @media (max-width: 768px) {
        margin: 20px auto;

        & p {
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
  }

  & .visit-section {
    & h2 {
      text-align: center;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    & .visit-details {
      max-width: 380px;
      margin: 0 auto;

      & p {
        font-size: 18px;
        line-height: 26px;
        /* font-size: 22px;
        line-height: 32px; */
        text-align: center;

        & strong {
          font-size: 12px;
          line-height: 32px;
          text-transform: uppercase;
        }

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 20px;

          & strong {
            font-size: 9px;
            line-height: 20px;
          }
        }
      }
    }

    & .map-link {
      & a {
        font-size: 10px;
        line-height: 32px;

        text-align: center;
        letter-spacing: 0.02em;

        color: #0b646f;

        display: block;
        width: 120px;
        height: 30px;
        margin: 0 auto;

        background: #fff;

        @media (hover: hover) and (pointer: fine) {
          transition: 250ms background-color ease;

          &:hover {
            background-color: #f5eadf;
          }
        }
      }
    }
  }

  & .funders-container {
    margin: 50px 0;

    & .single-funder {
      max-width: 380px;
      margin: 0 auto;

      & p {
        font-size: 18px;
        line-height: 26px;
        /* font-size: 22px;
        line-height: 32px; */
        text-align: center;
        margin: 0;

        & strong {
          font-size: 12px;
          line-height: 32px;
          text-transform: uppercase;
        }

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 20px;

          & strong {
            font-size: 9px;
            line-height: 20px;
          }
        }
      }
    }
  }

  & .offsite-locations {
    & .visit-details {
      margin: 50px auto;
    }

    & svg {
      display: block;
      margin: 50px auto;
    }
  }

  & .breadcrumbs {
    & p {
      font-size: 8px;
      line-height: 28px;

      color: #fff;

      margin: 0;
    }
  }
`;

const About = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  const height = use100vh();

  useEffect(() => {
    setPageSetup({
      pageTitle: ``,
      pageColor: `#DDE1D4`,
      pageType: `about`,
      filterArtists: [],
      selectedArtists: [],
      sortOption: null,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  const offsiteLocations = data.prismicAbout.data.offsite_locations.map(
    (location, index) => (
      <div className="visit-details" key={`single_offsite_location_${index}`}>
        <AboutIcon />

        <div className="address">
          <PrismicRichText field={location.offsite_address.richText} />
        </div>

        <div className="opening-hours">
          <PrismicRichText field={location.offsite_opening_hours.richText} />
        </div>

        <div className="map-link">
          <a href={location.offsite_map_link.url} target="_blank">
            View Map
          </a>
        </div>
      </div>
    )
  );

  return (
    <>
      <PageSeo
        seoTitle={data.prismicAbout.data.seo_title}
        seoText={data.prismicAbout.data.seo_description}
        seoImage={data.prismicAbout.data.seo_image}
      />

      <Page height={height}>
        {!isDesktop && (
          <section className="breadcrumbs">
            <p>
              <Link to={`/`}>HOME</Link> → About
            </p>
          </section>
        )}

        <section className="introduction-section news-gothic">
          <GatsbyImage
            image={data.prismicAbout.data.svg_text.gatsbyImageData}
            alt={
              data.prismicAbout.data.svg_text.alt !== null
                ? data.prismicAbout.data.svg_text.alt
                : ""
            }
            draggable="false"
            loading="lazy"
          />

          <div className="about-text-container">
            <PrismicRichText field={data.prismicAbout.data.text.richText} />
          </div>
        </section>

        <section className="visit-section">
          <PrismicRichText
            field={data.prismicAbout.data.visit_title.richText}
          />
          <div className="visit-details">
            <div className="address">
              <PrismicRichText
                field={data.prismicAbout.data.address.richText}
              />
            </div>

            <div className="opening-hours">
              <PrismicRichText
                field={data.prismicAbout.data.opening_hours.richText}
              />
            </div>

            <div className="map-link">
              <a href={data.prismicAbout.data.map_link.url} target="_blank">
                View Map
              </a>
            </div>
          </div>
          {offsiteLocations.length >= 1 && (
            <div className="offsite-locations">{offsiteLocations}</div>
          )}
        </section>

        <section className="funders-section">
          <div className="title-container">
            <PrismicRichText
              field={data.prismicAbout.data.funders_title.richText}
            />
          </div>

          <div className="text-container news-gothic">
            <PrismicRichText
              field={data.prismicAbout.data.funders_text.richText}
            />
          </div>
        </section>
      </Page>
    </>
  );
};

export default withPrismicPreview(About);

export const query = graphql`
  {
    prismicAbout {
      _previewable
      data {
        svg_text {
          url
          alt
          gatsbyImageData(layout: FULL_WIDTH)
          dimensions {
            width
            height
          }
        }
        text {
          richText
        }
        visit_title {
          richText
        }
        opening_hours {
          richText
        }
        address {
          richText
        }
        map_link {
          url
        }
        offsite_locations {
          offsite_opening_hours {
            richText
          }
          offsite_address {
            richText
          }
          offsite_map_link {
            url
          }
        }
        funders_title {
          richText
        }
        funders_text {
          richText
        }
        seo_title
        seo_description
        seo_image {
          url
        }
      }
    }
  }
`;
