import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html,
html.wf-loading {
  body {
    opacity: 0;
    transition: 250ms opacity ease;
  }
}

html,
body {
  overscroll-behavior: none;
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) button:focus > a > svg,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) a:focus > svg,
body:not(.user-is-tabbing) ul:focus,
body:not(.user-is-tabbing) ul:focus li,
body:not(.user-is-tabbing) ol:focus,
body:not(.user-is-tabbing) ol:focus li,
body:not(.user-is-tabbing) li:focus {
  outline: none;
}

*:focus {
  outline: #000 dashed 1px;
  outline-offset: 3px;
}

body {
  font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  /* font-size: 18px;
  line-height: 26px; */

  /* font-size: 14px; */
  /* line-height: 20px; */


  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.02em;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  margin: 0;


  @media(max-width: 768px){
    font-size: 12px;
    line-height: 17px;
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;
}

/* Title 1 */
h1{
  /* font-size: 50px; */
  /* line-height: 50px; */

  font-size: 40px;
  line-height: 40px;
}

/* Title 2 */
h2 {
  /* font-size: 22px;
  line-height: 60px; */

  font-size: 18px;
  line-height: 48px;
}

/* Heading 1 */
h3 {
  /* font-size: 30px;
  line-height: 32px; */

    font-size: 24px;
    line-height: 26px;
}

/* Heading 2 */
h4 {
  /* font-size: 18px;
  line-height: 24px; */

  font-size: 14px;
  line-height: 19px;
}

/* Heading 3 */
h5 {
  /* font-size: 12px;
  line-height: 14px; */

  font-size: 10px;
  line-height: 12px;
}

i, em {
  font-style: normal;
}

.news-gothic,
.news-gothic > p{
  font-family: "NewsGothicMTProBold", "Helvetica Neue", "Lucida Grande", sans-serif;
}

.center-text{
  text-align:center;
}


.breadcrumbs {

  & p {
    & a {
      transition: 250ms color ease;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #e9ccb2;
        }
      }
    }
  }
}

/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    appearance:none;
    border:0;

    padding:0;
    margin:0;

    cursor:pointer;
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, ol, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

.uppercase{
  text-transform:uppercase;
}

strong {
  font-weight: 400;
  font-style:normal;
}

textarea[type="hidden"] {
  appearance: none;
  background-color: initial;
  cursor: default;
  display: none !important;
  padding: initial;
  border: initial;
}


/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  ## Input Placeholders
  --------------------------------------------------------------*/

// ::-webkit-input-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// ::-moz-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// :-ms-input-placeholder{
//       color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// :-moz-placeholder {
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

//   }



/*--------------------------------------------------------------
  ## React Modal
--------------------------------------------------------------*/
.ReactModalPortal{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModalPortal-mailchimp,
.ReactModalPortal-gallery,
.ReactModalPortal-mobile-menu {
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 10000;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModalPortal-mobile-menu .ReactModal__Overlay,
.ReactModalPortal-mailchimp .ReactModal__Overlay {
  background-color: #f5eadf !important;
}

.ReactModalPortal-gallery .ReactModal__Overlay {
  background-color: #fff !important;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  width: 100%;
  height: 100%;

  inset: unset !important;
  background: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}
`;

export default GlobalStyle;
