import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

export const Header = ({ location, pageSetup, setPageSetup, isDesktop }) => {
  const data = useStaticQuery(graphql`
    {
      prismicMenu {
        data {
          is_alert_active
          header_menu {
            page {
              document {
                ... on PrismicEvents {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicAbout {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicShop {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.prismicMenu.data.header_menu.map((page, index) => {
    if (page.page.document.url === `/shop/`) {
      return (
        <li
          key={`single_header_menu_links_${index}_${page.page.document.id}`}
          className="uppercase"
        >
          <Link
            to={page.page.document.url}
            activeClassName="current"
            className={
              pageSetup.pageType === `shop` ||
              pageSetup.pageType === `product` ||
              pageSetup.pageType === `collections`
                ? `current`
                : ``
            }
          >
            {isDesktop ? (
              <>{page.page.document.data.title.text}</>
            ) : (
              <>All Products</>
            )}
          </Link>
        </li>
      );
    } else {
      return (
        <li
          key={`single_header_menu_links_${index}_${page.page.document.id}`}
          className="uppercase"
        >
          <Link
            to={page.page.document.url}
            activeClassName="current"
            partiallyActive
          >
            {page.page.document.data.title.text}
          </Link>
        </li>
      );
    }
  });

  return (
    <>
      {isDesktop ? (
        <DesktopMenu
          location={location}
          pageSetup={pageSetup}
          setPageSetup={setPageSetup}
          content={content}
          isDesktop={isDesktop}
          isAlertActive={data.prismicMenu.data.is_alert_active}
        />
      ) : (
        <MobileMenu
          location={location}
          pageSetup={pageSetup}
          setPageSetup={setPageSetup}
          content={content}
          isDesktop={isDesktop}
          isAlertActive={data.prismicMenu.data.is_alert_active}
        />
      )}
    </>
  );
};
