import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Context
import { useDraftProductsContext } from "../context/draft-products";

// Components
import { SingleProduct } from "../product/single-product";

const Container = styled.div`
  padding: 0 50px;
  margin: 0 0 70px 0;

  & .recommended-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    margin: 50px 0 20px 0;

    & h2 {
      /* font-size: 15px;
      line-height: 19px; */

      font-size: 12px;
      line-height: 15px;
    }

    @media (max-width: 768px) {
      margin: 0 0 15px 0;

      & h2 {
        font-size: 11px;
        line-height: 17px;
      }
    }
  }

  & .related-products-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 100px;

    padding: 0 50px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: unset;

      padding: 0;

      & .single-product {
        &:nth-of-type(3),
        &:nth-of-type(4) {
          display: none;
        }
      }
    }
  }

  @media (max-width: 960px) {
    padding: 20px 30px;
    margin: 0;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const RelatedProducts = ({ products, isDesktop }) => {
  // Draft Products
  const { draftProducts } = useDraftProductsContext();

  const relatedArtworks = products
    .filter(product => draftProducts.indexOf(product.shopifyId) === -1)
    .filter((product, index) => {
      if (index <= 3) {
        return product;
      }
    })
    .map((product, index) => (
      <SingleProduct
        product={product}
        index={index}
        key={`single_product_${index}_${product.id}`}
        isDesktop={isDesktop}
      />
    ));

  return (
    <Container className="content recommended-container">
      <div className="recommended-title">
        <h2>You Might Also Like</h2>
      </div>

      <div className="related-products-container">{relatedArtworks}</div>
    </Container>
  );
};
