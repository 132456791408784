import React, { useContext, useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazyload";
import { useAsyncFn } from "react-use";

// Context
import { StoreContext } from "../context/store-context";

// Components
import { AddedToCartPopup } from "./added-to-cart-popup";

// Utils
import { getMetafield } from "../utils/get-metafield";
import { formatPrice } from "../utils/format-price";

const SingleProductContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr minmax(5em, auto) 30px;
  height: 100%;

  @media (max-width: 768px) {
    grid-template-rows: 1fr auto 30px;
  }

  & p {
    margin: 0;
  }

  & .image-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (hover: hover) and (pointer: fine) {
      & img {
        transition: 250ms opacity ease;
      }

      &:hover {
        & img {
          opacity: 0.7 !important;
        }
      }
    }

    & a {
      display: block;
      width: 100%;
      position: relative;

      & .gatsby-image-wrapper {
        margin: 0 auto;
        display: block !important;

        & img {
          object-fit: contain !important;
        }
      }
    }
  }

  & .product-image {
  }

  & .product-title {
    text-align: center;

    font-size: 12px;
    line-height: 17px;

    color: #0b646f;

    max-width: 300px;
    margin: 20px auto 12px auto;

    @media (max-width: 768px) {
      font-size: 11px;
      line-height: 16px;
    }
  }

  & .price-container {
    &.sale-price {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      & p {
        margin: 0 5px;

        &.compare-at-price {
          text-decoration: line-through;
        }
      }
    }
  }

  & .product-buttons {
    text-align: center;
    margin: auto 0 0 0;

    & button,
    & a {
      width: 120px;
      height: 30px;

      display: block;
      margin: 0 auto;

      font-size: 10px;
      line-height: 32px;

      text-align: center;
      letter-spacing: 0.02em;

      color: #0b646f;

      background-color: #f5eadf;

      border: 0;
      padding: 0;

      @media (hover: hover) and (pointer: fine) {
        transition: 250ms color ease, 250ms background-color ease;

        &:hover {
          color: #fff;
          background-color: #0b646f;
        }
      }

      @media (max-width: 768px) {
        height: 25px;

        font-size: 8px;
        line-height: 24px;
      }
    }

    & .price-on-enquiry {
      width: 140px;
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 35px 0;
  }
`;

export const SingleProduct = ({ product, isDesktop }) => {
  const { addVariantToCart, checkout } = useContext(StoreContext);
  const [addedToCart, setAddedToCart] = useState(false);

  const addToCart = e => {
    e.preventDefault();
    addVariantToCart(product.variants[0].shopifyId, 1);
    setAddedToCart(true);
  };

  useEffect(() => {
    if (addedToCart) {
      const closeAddToCartPopup = setTimeout(() => {
        setAddedToCart(false);
      }, 5000);
      return () => clearTimeout(closeAddToCartPopup);
    }
  }, [addedToCart]);

  const isProductPriceOnEnquiry = getMetafield(product, "price_on_enquiry");
  const isOnePerCustomer = getMetafield(product, "purchase_limit");
  const [isAlreadyInCart, setIsAlreadyInCart] = useState(false);

  // Check if product is already in cart
  useEffect(() => {
    if (isOnePerCustomer && checkout?.lineItems) {
      const productInCart = checkout.lineItems.find(
        item => item.variant.product.id === product.shopifyId
      );
      setIsAlreadyInCart(!!productInCart);
    }
  }, [checkout, product.shopifyId, isOnePerCustomer]);

  return (
    <LazyLoad height={isDesktop ? 700 : 300} offset={isDesktop ? 150 : 200}>
      <SingleProductContainer className="single-product">
        <div className="image-container">
          <Link to={`/products/${product.handle}/`}>
            {product.featuredImage !== null && (
              <>
                {isDesktop ? (
                  <GatsbyImage
                    image={product.featuredImage.gatsbyImageData}
                    alt={product.title}
                    draggable="false"
                    imgClassName="desktop-image"
                    loading="lazy"
                  />
                ) : (
                  <GatsbyImage
                    image={product.mobileImage.gatsbyImageData}
                    alt={product.title}
                    draggable="false"
                    imgClassName="mobile-image"
                    loading="lazy"
                  />
                )}
              </>
            )}
          </Link>
        </div>

        <div className="product-title">
          <p>{getMetafield(product, "artist_name")}</p>
          <p>{product.title}</p>

          {isProductPriceOnEnquiry !== true &&
            isProductPriceOnEnquiry !== "true" && (
              <div
                className={`price-container ${
                  product.variants.length >= 1
                    ? product.variants[0].compareAtPrice !== null
                      ? `sale-price`
                      : ``
                    : ``
                }`}
              >
                {product.variants.length >= 1 && (
                  <>
                    {product.variants[0].compareAtPrice !== null && (
                      <p className="compare-at-price">
                        {formatPrice("GBP", product.variants[0].compareAtPrice)}
                      </p>
                    )}
                  </>
                )}

                <p className="price">
                  {formatPrice(
                    product.priceRangeV2.minVariantPrice.currencyCode,
                    product.priceRangeV2.minVariantPrice.amount
                  )}
                </p>
              </div>
            )}
        </div>

        <div className="product-buttons">
          {isProductPriceOnEnquiry !== true &&
            isProductPriceOnEnquiry !== "true" && (
              <>
                {product.variants.length === 1 &&
                  product.variants[0].availableForSale &&
                  !isAlreadyInCart && (
                    <button className="uppercase" onClick={e => addToCart(e)}>
                      Add to bag
                    </button>
                  )}

                {product.variants.length > 1 && (
                  <Link to={`/products/${product.handle}/`}>View Options</Link>
                )}

                {product.totalInventory === 0 &&
                  product.variants.some(
                    variant => variant.inventoryPolicy === "DENY"
                  ) && (
                    <Link to={`/products/${product.handle}/`}>Sold Out</Link>
                  )}

                {isAlreadyInCart && (
                  <Link to={`/products/${product.handle}/`}>View Options</Link>
                )}
              </>
            )}

          {(isProductPriceOnEnquiry === true ||
            isProductPriceOnEnquiry === "true") && (
            <Link
              to={`/products/${product.handle}/`}
              className="price-on-enquiry"
            >
              Price On Enquiry
            </Link>
          )}
        </div>
      </SingleProductContainer>

      {addedToCart && (
        <AddedToCartPopup
          prismic={false}
          product={product}
          price={formatPrice(
            product.priceRangeV2.minVariantPrice.currencyCode,
            product.priceRangeV2.minVariantPrice.amount
          )}
          artistName={getMetafield(product, "artist_name")}
        />
      )}
    </LazyLoad>
  );
};

export const SinglePrismicProduct = ({ product, isDesktop }) => {
  const { addVariantToCart, loading } = useContext(StoreContext);
  const [addedToCart, setAddedToCart] = useState(false);

  const addToCart = e => {
    e.preventDefault();
    addVariantToCart(product.product.variants[0].admin_graphql_api_id, 1);
    setAddedToCart(true);
  };

  const [productArtist, getProductArtist] = useAsyncFn(async id => {
    const response = await fetch(
      `/.netlify/functions/get-prismic-product-artist`,
      {
        method: "POST",
        body: JSON.stringify({
          id: id,
        }),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }
    );

    const result = await response.json();
    return result;
  }, []);

  const [priceOnEnquiryStatus, getPriceOnEnquiryStatus] = useAsyncFn(
    async id => {
      const response = await fetch(
        `/.netlify/functions/get-prismic-product-price-on-enquiry`,
        {
          method: "POST",
          body: JSON.stringify({
            id: id,
          }),
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        }
      );

      const result = await response.json();
      return result;
    },
    []
  );

  useEffect(() => {
    if (addedToCart) {
      const closeAddToCartPopup = setTimeout(() => {
        setAddedToCart(false);
      }, 5000);
      return () => clearTimeout(closeAddToCartPopup);
    }
  }, [addedToCart]);

  useEffect(() => {
    if (product.product.admin_graphql_api_id !== null) {
      getProductArtist(product.product.admin_graphql_api_id);
      getPriceOnEnquiryStatus(product.product.admin_graphql_api_id);
    }
  }, []);

  return (
    <LazyLoad height={isDesktop ? 700 : 300} offset={isDesktop ? 150 : 200}>
      <SingleProductContainer className="single-product">
        <div className="image-container">
          <Link to={`/products/${product.product.handle}/`}>
            {product.product.image !== null && (
              <img
                src={product.product.image.src}
                alt={product.product.title}
                draggable="false"
                loading="lazy"
              />
            )}
          </Link>
        </div>

        <div className="product-title">
          <p>
            {productArtist.loading === false &&
            productArtist.value !== undefined
              ? productArtist.value
              : ``}
          </p>
          <p>{product.product.title}</p>

          {priceOnEnquiryStatus.loading === false &&
          priceOnEnquiryStatus.value !== undefined ? (
            <>
              {priceOnEnquiryStatus.value !== true &&
              priceOnEnquiryStatus.value !== "true" ? (
                <p>{formatPrice("GBP", product.product.variants[0].price)}</p>
              ) : (
                <p>Price On Enquiry</p>
              )}
            </>
          ) : (
            ``
          )}
        </div>

        <div className="product-buttons">
          {priceOnEnquiryStatus.loading === false &&
            priceOnEnquiryStatus.value !== undefined && (
              <>
                {priceOnEnquiryStatus.value !== true &&
                  priceOnEnquiryStatus.value !== "true" && (
                    <>
                      {product.product.variants.length === 1 &&
                        product.product.status === `active` && (
                          <button
                            className="uppercase"
                            onClick={e => addToCart(e)}
                          >
                            Add to bag
                          </button>
                        )}
                    </>
                  )}
              </>
            )}

          {product.product.variants.length > 1 && (
            <Link to={`/products/${product.product.handle}/`}>
              View Options
            </Link>
          )}
        </div>
      </SingleProductContainer>

      {addedToCart && (
        <AddedToCartPopup
          prismic={true}
          product={product}
          price={formatPrice(`GBP`, product.product.variants[0].price)}
          artistName={
            productArtist.loading === false && productArtist.value !== undefined
              ? productArtist.value
              : ``
          }
        />
      )}
    </LazyLoad>
  );
};

export const SingleSearchResultProduct = ({ product, index }) => {
  const { addVariantToCart, loading } = useContext(StoreContext);
  const [addedToCart, setAddedToCart] = useState(false);

  const addToCart = e => {
    e.preventDefault();
    addVariantToCart(product.data.variants[0].shopifyId, 1);
    setAddedToCart(true);
  };

  const isProductPriceOnEnquiry = getMetafield(
    product.data,
    "price_on_enquiry"
  );

  useEffect(() => {
    if (addedToCart) {
      const closeAddToCartPopup = setTimeout(() => {
        setAddedToCart(false);
      }, 5000);
      return () => clearTimeout(closeAddToCartPopup);
    }
  }, [addedToCart]);

  return (
    <>
      <SingleProductContainer className="single-product">
        <div className="image-container">
          <Link to={`/products/${product.url}/`}>
            {product.data.featuredImage !== null && (
              <GatsbyImage
                image={product.data.featuredImage.gatsbyImageData}
                alt={product.data.title}
                draggable="false"
                loading="lazy"
              />
            )}
          </Link>
        </div>

        <div className="product-title">
          <p>{getMetafield(product.data, "artist_name")}</p>
          <p>{product.data.title}</p>

          {(isProductPriceOnEnquiry === true ||
            isProductPriceOnEnquiry === "true") && <p>Price On Enquiry</p>}

          {isProductPriceOnEnquiry !== true &&
            isProductPriceOnEnquiry !== "true" && (
              <p>
                {formatPrice(
                  product.data.priceRangeV2.minVariantPrice.currencyCode,
                  product.data.priceRangeV2.minVariantPrice.amount
                )}
              </p>
            )}
        </div>

        <div className="product-buttons">
          <Link to={`/products/${product.url}/`}>View Options</Link>
        </div>
      </SingleProductContainer>

      {addedToCart && (
        <AddedToCartPopup
          prismic={false}
          product={product.data}
          price={formatPrice(
            "GBP",
            product.data.priceRangeV2.minVariantPrice.amount
          )}
          artistName={getMetafield(product, "artist_name")}
        />
      )}
    </>
  );
};
