import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

export const useFooterLinks = () => {
  const data = useStaticQuery(graphql`
    {
      prismicMenu {
        data {
          footer_menu {
            page {
              document {
                ... on PrismicDeliveryAndReturns {
                  id
                  data {
                    title {
                      text
                    }
                  }
                  url
                }
                ... on PrismicTermsAndConditions {
                  id
                  data {
                    title {
                      text
                    }
                  }
                  url
                }
                ... on PrismicContact {
                  id
                  data {
                    title {
                      text
                    }
                  }
                  url
                }
                ... on PrismicPrivacyPolicy {
                  id
                  data {
                    title {
                      text
                    }
                  }
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const footer = data.prismicMenu.data.footer_menu.map((page, index) => {
    return (
      <li
        key={`single_footer_menu_links_${index}_${page.page.document.id}`}
        className="uppercase"
      >
        <Link
          to={page.page.document.url}
          activeClassName="current"
          state={{ from: `website` }}
        >
          {page.page.document.data.title.text}
        </Link>
      </li>
    );
  });

  return footer;
};
