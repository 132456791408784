/**
 * Formats a currency according to the user's locale
 * @param {string} currency The ISO currency code
 * @param {number} value The amount to format
 * @returns {string}
 */
export const formatPrice = (currency, value) => {
  const formatter = new Intl.NumberFormat("en-GB", {
    currency,
    style: "currency",
  });

  const parts = formatter.formatToParts(value);
  const formattedParts = parts.map(part => {
    if (part.type === "fraction" && part.value === "00") {
      return "";
    }
    if (
      part.type === "decimal" &&
      parts.find(p => p.type === "fraction").value === "00"
    ) {
      return "";
    }
    return part.value;
  });

  return formattedParts.join("");
};

export const getCurrencySymbol = (currency, locale = undefined) => {
  if (!currency) {
    return;
  }

  const formatter = Intl.NumberFormat(locale, {
    currency,
    style: "currency",
  });

  const parts = formatter.formatToParts(100);

  const { value: symbol } = parts.find(part => part.type === "currency");

  const formatted = formatter.format(100);

  const symbolAtEnd = formatted.endsWith(symbol);

  return { symbol, symbolAtEnd };
};
