import React from "react";

// Components
import { Search } from "./search";

// Hooks
import { UseSearchData } from "../hooks/useSearchData";

// Icons
import { SearchIcon } from "../icons/menu-icons";

const SearchIndex = ({
  isSearchOpen,
  setIsSearchOpen,
  underlineColor,
  hoverColor,
  // location,
  fill,
  isDesktop,
}) => {
  const data = UseSearchData();

  if (isSearchOpen && data !== null) {
    return (
      <Search
        index={data.index}
        store={data.store}
        isSearchOpen={isSearchOpen}
        setIsSearchOpen={setIsSearchOpen}
        underlineColor={underlineColor}
        hoverColor={hoverColor}
        fill={fill}
        isDesktop={isDesktop}
      />
    );
  } else {
    return (
      <button
        type="button"
        onClick={() => setIsSearchOpen(true)}
        aria-label="Open search"
      >
        <SearchIcon fill={fill} />
      </button>
    );
  }
};

export default SearchIndex;
