import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Utils
import { ConditionalWrapper } from "../components/utils/conditional-wrapper";

// Context
import { PageSetup } from "../components/context/page-context";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Components
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";
import { Gallery } from "../components/images/gallery";
import { VideoBanner } from "../components/video/banner-video";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  margin: 50px 0 0 0;

  @media (max-width: 960px) {
    margin: 30px 0 0 0;
  }

  & .text-container {
    &.background-buff {
      background-color: #f5eadf;

      & .rollover-text-container {
        & p {
          color: #0b646f;
        }
      }

      & .call-to-action {
        & a {
          @media (hover: hover) and (pointer: fine) {
            transition: 250ms color ease, 250ms background-color ease;

            &:hover {
              color: #fff;
              background-color: #0b646f;
            }
          }
        }
      }
    }

    &.background-mustard {
      background-color: #cfa14d;

      & .text-container-inner {
        & h2,
        & p {
          color: #fff;
        }
      }

      & .call-to-action {
        & a {
          @media (hover: hover) and (pointer: fine) {
            transition: 250ms color ease, 250ms background-color ease;

            &:hover {
              color: #fff;
              background-color: #0b646f;
            }
          }
        }
      }
    }

    &.background-green {
      background-color: #0b646f;

      & .text-container-inner {
        & h2,
        & p {
          color: #fff;
        }
      }

      & .rollover-text-container {
        & p {
          color: #fff;
        }
      }

      & .call-to-action {
        & a {
          @media (hover: hover) and (pointer: fine) {
            transition: 250ms background-color ease;

            &:hover {
              background-color: #f5eadf;
            }
          }
        }
      }
    }
  }

  & .slideshow {
    & img {
      object-fit: cover !important;
    }
  }

  & .slideshow,
  & .single-video,
  & .single-image {
    position: relative;

    max-height: 100%;
    overflow: hidden;

    & .image-container,
    & .gatsby-image-wrapper {
      // width: 100%;
      max-height: 100%;
      overflow: hidden;
      z-index: 1;
    }

    & .svg-text-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      height: 100%;
      padding: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      z-index: 10;

      & img {
        object-fit: contain !important;
        max-height: 530px;

        @media (max-width: 970px) {
          max-height: 400px;
        }

        @media (max-width: 800px) {
          max-height: 300px;
        }
      }
    }

    &.first {
      max-height: calc(100svh - 50px - 50px);

      & .image-container,
      & .gatsby-image-wrapper {
        max-height: calc(100svh - 50px - 50px);
      }
    }
  }

  & .product-collections {
    background-color: #fff;
    padding: 0 25px;

    line-height: 50px;

    @media (max-width: 960px) {
      padding: 0 10px;
    }

    @media (max-width: 769px) {
      display: none;
    }

    & ol {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      & li {
        & a {
          /* font-size: 14px; */
          font-size: 13px;
          letter-spacing: 0.02em;

          color: #0b646f;
          transition: 250ms color ease;

          & .hover-arrow {
            opacity: 0;
            color: #debb9b;
            transition: 250ms opacity ease;
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #debb9b;

              & .hover-arrow {
                opacity: 1;
              }
            }
          }

          @media (max-width: 1470px) {
            font-size: 12px;
          }
        }

        @media (max-width: 1200px) {
          &:last-of-type {
            display: none;
          }
        }

        @media (max-width: 1050px) {
          &:nth-of-type(8n + 0) {
            display: none;
          }
        }

        @media (max-width: 900px) {
          &:nth-of-type(7n + 0) {
            display: none;
          }
        }
      }
    }
  }

  & .text-and-products {
    display: grid;

    & .aspect-ratio-image-container {
      display: grid;
    }

    &.desktop-layout {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.mobile-layout {
      grid-template-columns: 1fr 1fr;
    }

    &.text-position-right {
      & .text-container {
        order: 3;
      }

      & .product-container {
        &:first-of-type {
          order: 1;
        }
        &:last-of-type {
          order: 2;
        }
      }
    }

    & .product-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      @media (hover: hover) and (pointer: fine) {
        & img {
          transition: 250ms opacity ease;
        }

        &:hover {
          & img {
            opacity: 0.7 !important;
          }
        }
      }

      & .aspect-ratio-image-container {
        width: 100%;
        height: 100%;

        & a {
          display: block;
          width: 100%;
          height: 100%;

          & img {
            object-fit: contain;
            height: 100%;
            width: 100%;
          }
        }

        & .content {
          padding: 25px;

          @media (max-width: 768px) {
            padding: 10px;
          }
        }
      }
    }

    & .text-container {
      padding: 25px;

      @media (max-width: 768px) {
        padding: 10px;
      }

      & p {
        font-size: 18px;
        line-height: 26px;

        /* font-size: 14px;
        line-height: 21px; */

        @media (max-width: 800px) {
          font-size: 12px;
          line-height: 17px;
        }
      }

      & h2 {
        font-size: 48px;
        font-size: clamp(20px, 3vw, 48px);

        /* font-size: 38px; */
        /* font-size: clamp(20px, 3vw, 38px); */
        line-height: 125%;

        text-align: center;

        color: #0b646f;

        & strong {
          font-size: 22px;
          font-size: clamp(14px, 1.5vw, 22px);

          /* font-size: 18px; */
          /* font-size: clamp(14px, 1.5vw, 18px); */
          line-height: 200%;
          display: block;

          @media (max-width: 800px) {
            font-size: 6px;
            line-height: 12px;
          }
        }

        @media (max-width: 800px) {
          font-size: 16px;
          line-height: 18px;
        }
      }

      & .content {
        display: grid;
        grid-template-rows: auto 30px;

        @media (max-width: 800px) {
          grid-template-rows: auto 25px;
        }
      }

      /* & .spacer {
        @media (max-width: 768px) {
          display: none;
        }
      } */

      & .text-container-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        padding: 0 0 25px 0;

        @media (max-width: 768px) {
          padding: 0 0 10px 0;
        }

        & a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          width: 100%;
          height: 100%;
        }

        & .main-text-container {
          display: block;
        }

        & .rollover-text-container {
          max-width: 380px;
          margin: 0 auto;

          display: none;

          & p {
            text-align: center;
          }
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            & .main-text-container {
              display: none;
            }

            & .rollover-text-container {
              display: block;
            }
          }
        }
      }

      & .call-to-action {
        font-size: 12px;
        line-height: 31px;

        text-align: center;
        letter-spacing: 0.02em;

        color: #0b646f;

        width: fit-content;
        margin: 0 auto;

        & a {
          display: block;

          min-width: 175px;
          height: 30px;
          line-height: 31px;

          padding: 0 10px;
          background-color: #fff;
        }

        @media (max-width: 800px) {
          font-size: 8px;
          line-height: 26px;

          & a {
            height: 25px;
            min-width: 105px;
            padding: 0 5px;

            line-height: 26px;
          }
        }
      }
    }
  }

  & .image-with-text {
    display: grid;

    & .aspect-ratio-image-container {
      display: grid;
    }

    &.text-position-left {
      grid-template-columns: 1fr 2fr;

      @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      & .text-container {
        order: 1;
      }

      & .image-container {
        order: 2;
      }
    }

    &.text-position-right {
      grid-template-columns: 2fr 1fr;

      @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      & .text-container {
        order: 2;
      }

      & .image-container {
        order: 1;
      }
    }

    & .image-container {
      & .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }

    & .text-container {
      padding: 25px;

      @media (max-width: 768px) {
        padding: 10px;
      }

      & p {
        /* font-size: 14px;
        line-height: 21px; */

        font-size: 18px;
        line-height: 26px;

        @media (max-width: 800px) {
          font-size: 12px;
          line-height: 17px;
        }
      }

      & h2 {
        font-size: 48px;
        font-size: clamp(20px, 3vw, 48px);

        /* font-size: 38px; */
        /* font-size: clamp(20px, 3vw, 38px); */
        line-height: 125%;
        text-align: center;

        color: #fff;

        & strong {
          font-size: 22px;
          font-size: clamp(14px, 1.5vw, 22px);

          /* font-size: 18px; */
          /* font-size: clamp(14px, 1.5vw, 18px); */
          line-height: 200%;
          display: block;

          @media (max-width: 800px) {
            font-size: 6px;
            line-height: 12px;
          }
        }

        @media (max-width: 800px) {
          font-size: 16px;
          line-height: 18px;
        }
      }

      & .content {
        display: grid;
        grid-template-rows: auto 30px;

        @media (max-width: 800px) {
          grid-template-rows: auto 25px;
        }
      }

      & .text-container-inner {
        margin: auto;

        padding: 0 0 25px 0;

        @media (max-width: 768px) {
          padding: 0 0 10px 0;
        }
      }

      & .call-to-action {
        font-size: 12px;
        line-height: 31px;

        text-align: center;
        letter-spacing: 0.02em;

        color: #0b646f;

        width: fit-content;
        margin: 0 auto;

        & a {
          display: block;

          min-width: 175px;
          height: 30px;
          line-height: 31px;

          padding: 0 10px;
          background-color: #fff;
        }

        @media (max-width: 800px) {
          font-size: 8px;
          line-height: 26px;

          & a {
            height: 25px;
            min-width: 105px;
            padding: 0 5px;

            line-height: 26px;
          }
        }
      }
    }
  }
`;

const Index = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  useEffect(() => {
    setPageSetup({
      pageTitle: ``,
      pageColor: `#DDE1D4`,
      pageType: `home`,
      filterArtists: [],
      selectedArtists: [],
      sortOption: null,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  const content = data.prismicHomepage.data.body.map((content, index) => {
    if (content.slice_type === "slideshow") {
      const media = content.items.map((image, innerImageIndex) => (
        <div
          className="module single-image"
          key={`single_module_${content.id}_slideshow_${innerImageIndex}`}
        >
          <div className="image-container">
            {image.image.url !== null && (
              <GatsbyImage
                image={image.image.gatsbyImageData}
                alt={image.image.alt !== null ? image.image.alt : ""}
                draggable="false"
                loading="lazy"
              />
            )}

            <div className="svg-text-container">
              {image.svg_text.url !== null && (
                <GatsbyImage
                  image={image.svg_text.gatsbyImageData}
                  alt={image.svg_text.alt !== null ? image.svg_text.alt : ""}
                  draggable="false"
                  loading="lazy"
                />
              )}
            </div>
          </div>
        </div>
      ));

      return (
        <div className={`module slideshow ${index === 0 ? `first` : ``}`}>
          <Gallery media={media} autoplay={true} />
        </div>
      );
    }

    if (content.slice_type === "video") {
      return (
        <div
          className={`module single-video ${index === 0 ? `first` : ``}`}
          key={`single_module_${content.id}`}
        >
          <VideoBanner content={content} />
        </div>
      );
    }

    if (content.slice_type === "image") {
      return (
        <div
          className={`module single-image ${index === 0 ? `first` : ``}`}
          key={`single_module_${content.id}`}
        >
          <div className="image-container">
            {isDesktop ? (
              <>
                {content.primary.image.url !== null && (
                  <GatsbyImage
                    image={content.primary.image.gatsbyImageData}
                    alt={
                      content.primary.image.alt !== null
                        ? content.primary.image.alt
                        : ""
                    }
                    draggable="false"
                    loading="lazy"
                  />
                )}
              </>
            ) : (
              <>
                {content.primary.mobileImage.url !== null && (
                  <GatsbyImage
                    image={content.primary.mobileImage.gatsbyImageData}
                    alt={
                      content.primary.mobileImage.alt !== null
                        ? content.primary.mobileImage.alt
                        : ""
                    }
                    draggable="false"
                    className="mobile-image"
                    loading="lazy"
                  />
                )}
              </>
            )}

            <div className="svg-text-container">
              {content.primary.svg_text.url !== null && (
                <GatsbyImage
                  image={content.primary.svg_text.gatsbyImageData}
                  alt={
                    content.primary.svg_text.alt !== null
                      ? content.primary.svg_text.alt
                      : ""
                  }
                  draggable="false"
                  loading="lazy"
                />
              )}
            </div>
          </div>
        </div>
      );
    }

    if (content.slice_type === "product_collections") {
      const collections = content.items.map((collection, index) => (
        <li
          key={`single_collection_${index}_${collection.collection.id}`}
          className="uppercase"
        >
          <Link to={`/collections/${collection.collection.handle}/`}>
            {collection.collection.title}
          </Link>
        </li>
      ));

      if (isDesktop) {
        return (
          <div
            className="module product-collections"
            key={`single_module_${content.id}`}
          >
            <ol>
              <li>
                <Link to={`/shop/`}>All Products</Link>
              </li>
              {collections}
            </ol>
          </div>
        );
      } else {
        return null;
      }
    }

    if (content.slice_type === "text_and_products") {
      return (
        <div
          className={`module ${
            index === 0 ? `first` : ``
          } text-and-products text-position-${content.primary.text_position.toLowerCase()} ${
            isDesktop ? `desktop-layout` : `mobile-layout`
          }`}
          key={`single_module_${content.id}`}
        >
          <div
            className={`text-container column background-${content.primary.text_background_color.toLowerCase()}`}
          >
            <AspectRatioImageContainer image={null} padding={100}>
              <div className="text-container-inner">
                <ConditionalWrapper
                  condition={content.primary.call_to_action_link.url}
                  wrapper={children => (
                    <Link to={content.primary.call_to_action_link.url}>
                      {children}
                    </Link>
                  )}
                >
                  <div className="main-text-container">
                    <PrismicRichText field={content.primary.text.richText} />
                  </div>

                  <div className="rollover-text-container news-gothic">
                    <PrismicRichText
                      field={content.primary.rollover_text.richText}
                    />
                  </div>
                </ConditionalWrapper>
              </div>

              <div className="call-to-action">
                <ConditionalWrapper
                  condition={content.primary.call_to_action_link.url}
                  wrapper={children => (
                    <Link to={content.primary.call_to_action_link.url}>
                      {children}
                    </Link>
                  )}
                >
                  {content.primary.call_to_action}
                </ConditionalWrapper>
              </div>
            </AspectRatioImageContainer>
          </div>

          {content.primary.first_product !== null && (
            <div className="product-container column">
              <AspectRatioImageContainer image={null} padding={100}>
                <Link
                  to={`/products/${content.primary.first_product.handle}/`}
                  aria-label={content.primary.first_product.title}
                >
                  <img
                    src={content.primary.first_product.image.src}
                    alt={content.primary.first_product.title}
                    loading="lazy"
                  />{" "}
                </Link>
              </AspectRatioImageContainer>
            </div>
          )}

          {isDesktop && content.primary.second_product !== null && (
            <div className="product-container column">
              <AspectRatioImageContainer image={null} padding={100}>
                <Link
                  to={`/products/${content.primary.second_product.handle}/`}
                  aria-label={content.primary.second_product.title}
                >
                  <img
                    src={content.primary.second_product.image.src}
                    alt={content.primary.second_product.title}
                    loading="lazy"
                  />
                </Link>
              </AspectRatioImageContainer>
            </div>
          )}
        </div>
      );
    }

    if (content.slice_type === "text_and_products_collection") {
      return (
        <div
          className={`module  ${
            index === 0 ? `first` : ``
          } text-and-products text-position-${content.primary.text_position.toLowerCase()} ${
            isDesktop ? `desktop-layout` : `mobile-layout`
          }`}
          key={`single_module_${content.id}`}
        >
          <div
            className={`text-container column background-${content.primary.text_background_color.toLowerCase()}`}
          >
            <AspectRatioImageContainer image={null} padding={100}>
              <div className="text-container-inner">
                <ConditionalWrapper
                  condition={content.primary.call_to_action_link}
                  wrapper={children => (
                    <Link
                      to={`/collections/${content.primary.call_to_action_link.handle}/`}
                    >
                      {children}
                    </Link>
                  )}
                >
                  <div className="main-text-container">
                    <PrismicRichText field={content.primary.text.richText} />
                  </div>

                  <div className="rollover-text-container news-gothic">
                    <PrismicRichText
                      field={content.primary.rollover_text.richText}
                    />
                  </div>
                </ConditionalWrapper>
              </div>

              <div className="call-to-action">
                <ConditionalWrapper
                  condition={content.primary.call_to_action_link}
                  wrapper={children => (
                    <Link
                      to={`/collections/${content.primary.call_to_action_link.handle}/`}
                    >
                      {children}
                    </Link>
                  )}
                >
                  {content.primary.call_to_action}
                </ConditionalWrapper>
              </div>
            </AspectRatioImageContainer>
          </div>

          {content.primary.first_product !== null && (
            <div className="product-container column">
              <AspectRatioImageContainer image={null} padding={100}>
                <Link
                  to={`/products/${content.primary.first_product.handle}/`}
                  aria-label={content.primary.first_product.title}
                >
                  <img
                    src={content.primary.first_product.image.src}
                    alt={content.primary.first_product.title}
                    loading="lazy"
                  />{" "}
                </Link>
              </AspectRatioImageContainer>
            </div>
          )}

          {isDesktop && content.primary.second_product !== null && (
            <div className="product-container column">
              <AspectRatioImageContainer image={null} padding={100}>
                <Link
                  to={`/products/${content.primary.second_product.handle}/`}
                  aria-label={content.primary.second_product.title}
                >
                  <img
                    src={content.primary.second_product.image.src}
                    alt={content.primary.second_product.title}
                    loading="lazy"
                  />
                </Link>
              </AspectRatioImageContainer>
            </div>
          )}
        </div>
      );
    }

    if (content.slice_type === "image_with_text") {
      return (
        <div
          className={`module  ${
            index === 0 ? `first` : ``
          } image-with-text text-position-${content.primary.text_position.toLowerCase()}`}
          key={`single_module_${content.id}`}
        >
          <div className="image-container">
            {content.primary.image.url !== null && (
              <GatsbyImage
                image={content.primary.image.gatsbyImageData}
                alt={
                  content.primary.image.alt !== null
                    ? content.primary.image.alt
                    : ""
                }
                draggable="false"
                loading="lazy"
              />
            )}
          </div>

          <div
            className={`text-container background-${content.primary.text_background_color.toLowerCase()}`}
          >
            <AspectRatioImageContainer
              image={null}
              padding={isDesktop ? 144 : 100}
            >
              <div className="text-container-inner">
                <PrismicRichText field={content.primary.text.richText} />
              </div>

              <div className="call-to-action">
                <ConditionalWrapper
                  condition={content.primary.call_to_action_link.url}
                  wrapper={children => {
                    if (
                      content.primary.call_to_action_link.link_type === `Web`
                    ) {
                      return (
                        <a
                          href={content.primary.call_to_action_link.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {children}
                        </a>
                      );
                    } else {
                      return (
                        <Link to={content.primary.call_to_action_link.url}>
                          {children}
                        </Link>
                      );
                    }
                  }}
                >
                  {content.primary.call_to_action}
                </ConditionalWrapper>
              </div>
            </AspectRatioImageContainer>
          </div>
        </div>
      );
    }

    if (content.slice_type === "image_with_text_collection") {
      return (
        <div
          className={`module  ${
            index === 0 ? `first` : ``
          } image-with-text text-position-${content.primary.text_position.toLowerCase()}`}
          key={`single_module_${content.id}`}
        >
          <div className="image-container">
            {content.primary.image.url !== null && (
              <GatsbyImage
                image={content.primary.image.gatsbyImageData}
                alt={
                  content.primary.image.alt !== null
                    ? content.primary.image.alt
                    : ""
                }
                draggable="false"
                loading="lazy"
              />
            )}
          </div>

          <div
            className={`text-container background-${content.primary.text_background_color.toLowerCase()}`}
          >
            <AspectRatioImageContainer
              image={null}
              padding={isDesktop ? 144 : 100}
            >
              <div className="text-container-inner">
                <PrismicRichText field={content.primary.text.richText} />
              </div>
              <div className="call-to-action">
                <ConditionalWrapper
                  condition={content.primary.call_to_action_link}
                  wrapper={children => (
                    <Link
                      to={`/collections/${content.primary.call_to_action_link.handle}/`}
                    >
                      {children}
                    </Link>
                  )}
                >
                  {content.primary.call_to_action}
                </ConditionalWrapper>
              </div>
            </AspectRatioImageContainer>
          </div>
        </div>
      );
    }
  });

  return (
    <>
      <PageSeo seoTitle={null} seoText={null} seoImage={null} />
      <Page>{content}</Page>
    </>
  );
};

export default withPrismicPreview(Index);

export const query = graphql`
  {
    prismicHomepage {
      _previewable
      data {
        body {
          ... on PrismicHomepageDataBodyImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData(imgixParams: { ar: "1440:680" })
                alt
                url
              }
              mobileImage: image {
                gatsbyImageData(aspectRatio: 1)
                alt
                url
              }
              svg_text {
                gatsbyImageData
                alt
                url
              }
            }
          }
          ... on PrismicHomepageDataBodyImageWithText {
            id
            slice_type
            primary {
              call_to_action
              call_to_action_link {
                link_type
                url
              }
              text {
                richText
              }
              text_position
              text_background_color
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5)
                url
              }
            }
          }
          ... on PrismicHomepageDataBodyNotificationBar {
            id
            slice_type
            primary {
              text {
                richText
              }
            }
          }
          ... on PrismicHomepageDataBodyProductCollections {
            id
            slice_type
            items {
              collection {
                id
                title
                handle
              }
            }
          }
          ... on PrismicHomepageDataBodySlideshow {
            id
            slice_type
            items {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 2.8)
                url
              }
              svg_text {
                alt
                gatsbyImageData
                url
              }
            }
          }
          ... on PrismicHomepageDataBodyTextAndProducts {
            id
            slice_type
            primary {
              text {
                richText
              }
              rollover_text {
                richText
              }
              text_position
              call_to_action
              call_to_action_link {
                url
              }
              text_background_color
              first_product {
                image {
                  src
                  width
                  height
                }
                handle
                title
              }
              second_product {
                image {
                  height
                  src
                  width
                }
                handle
                title
              }
            }
          }
          ... on PrismicHomepageDataBodyVideo {
            id
            slice_type
            primary {
              video {
                id
                embed_url
                html
              }
              svg_text {
                alt
                gatsbyImageData
                url
              }
            }
          }
          ... on PrismicHomepageDataBodyImageWithTextCollection {
            id
            slice_type
            primary {
              call_to_action_link {
                handle
              }
              call_to_action
              text {
                richText
              }
              text_position
              text_background_color
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5)
                url
              }
            }
          }
          ... on PrismicHomepageDataBodyTextAndProductsCollection {
            id
            slice_type
            primary {
              text {
                richText
              }
              rollover_text {
                richText
              }
              text_position
              call_to_action
              call_to_action_link {
                handle
              }
              text_background_color
              first_product {
                image {
                  src
                  width
                  height
                }
                handle
                title
              }
              second_product {
                handle
                title
                image {
                  src
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;
