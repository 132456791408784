import React, { useState } from "react";
import styled from "styled-components";
// import { useLocation } from "react-use";

// Icons
import { MenuToggleIcon } from "../icons/menu-icons";

// Search
import SearchIndex from "../search/search-index";

const CloseMobileFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding: 0 10px;

  & button {
    line-height: 30px;

    &.menu-toggle-is-open {
      & svg {
        display: block;
        margin: 10px 0 0 0;

        transform: rotate(45deg);
      }
    }
  }

  & svg {
    &.search-icon-svg {
      display: block;
      margin: 0;

      &.desktop {
        display: none;
      }
    }
  }
`;

export const CloseMobileFilters = ({ setOpenFilter }) => {
  // Search
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  // const location = useLocation();

  return (
    <CloseMobileFiltersContainer>
      <button
        onClick={() => setOpenFilter(null)}
        className="menu-toggle-is-open"
      >
        <MenuToggleIcon fill={`#0B646F`} />
      </button>

      <SearchIndex
        isSearchOpen={isSearchOpen}
        setIsSearchOpen={setIsSearchOpen}
        // location={location}
        fill={`#0B646F`}
      />
    </CloseMobileFiltersContainer>
  );
};
