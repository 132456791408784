import React from "react";

export const CloseIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.19248 6.60596L0.596203 11.2022L2.01042 12.6164L6.60669 8.02017L11.203 12.6164L12.6172 11.2022L8.0209 6.60596L12.617 2.00984L11.2028 0.595628L6.60669 5.19174L2.01057 0.595628L0.59636 2.00984L5.19248 6.60596Z"
      fill="#0B646F"
    />
  </svg>
);
