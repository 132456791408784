import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";
import { use100vh } from "react-div-100vh";

// Get Urls correct
import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import { linkResolver } from "../link-resolver/linkResolver";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";

// Context
import { PageSetup } from "../context/page-context";
import { DraftProductsProvider } from "../context/draft-products";

const Container = styled.div`
  position: relative;
  min-height: calc(${props => props.height}px - 50px);

  &.page-type-home,
  &.page-type-shop,
  &.page-type-search,
  &.page-type-collections,
  &.page-type-artist,
  &.page-type-product,
  &.page-type-cart,
  &.page-type-not-found {
    background-color: #fff;

    & header,
    & footer {
      background-color: #fff;
      color: #0b646f;

      & a,
      & button {
        color: #0b646f;
      }

      & svg {
        & path {
          fill: #0b646f;
        }
      }
    }
  }

  &.page-type-events {
    background-color: #fff;

    & header {
      background-color: #fff;
      color: #0b646f;

      & a,
      & button {
        color: #0b646f;
      }

      & svg {
        & path {
          fill: #0b646f;
        }
      }
    }

    & footer {
      background-color: #f5eadf;

      color: #0b646f;

      & a,
      & button {
        color: #0b646f;
      }

      & svg {
        & path {
          fill: #0b646f;
        }
      }
    }
  }

  &.page-type-about {
    background-color: #0b646f;
    color: #fff;

    & header,
    & footer {
      background-color: #0b646f;
      color: #fff;

      & a,
      & button {
        color: #fff;
      }

      & svg {
        & path {
          fill: #fff;
        }
      }
    }

    & header {
      & .input-container {
        & input {
          border-bottom: 1px solid #fff;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  &.page-type-event {
    background-color: #f5eadf;
    color: #fff;

    & header {
      background-color: #f5eadf;
      color: #0b646f;

      & a,
      & button {
        color: #0b646f;
      }

      & svg {
        & path {
          fill: #0b646f;
        }
      }
    }

    & footer {
      background-color: #fff;
      color: #0b646f;

      & a,
      & button {
        color: #0b646f;
      }

      & svg {
        & path {
          fill: #0b646f;
        }
      }
    }
  }

  &.page-type-contact,
  &.page-type-delivery {
    background-color: #f5eadf;

    & header,
    & footer {
      display: none;
    }
  }
`;

const Layout = ({ children }) => {
  const [pageSetup, setPageSetup] = useState({
    pageTitle: ``,
    pageColor: `#fff`,
    pageType: ``,
    filterArtists: [],
    selectedArtists: [],
    sortOption: null,
    priceOption: null,
    artistOption: [],
  });

  const height = use100vh();

  // Script for dealing with focus on tab key rather than on click
  // https://medium.com/hackernoon/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
  const handleFirstTab = e => {
    if (e.keyCode === 9) {
      document.body.classList.add("user-is-tabbing");
      window.removeEventListener("keydown", handleFirstTab);
      window.addEventListener("mousedown", handleMouseDownOnce);
    }
  };

  const handleMouseDownOnce = () => {
    document.body.classList.remove("user-is-tabbing");
    window.removeEventListener("mousedown", handleMouseDownOnce);
    window.addEventListener("keydown", handleFirstTab);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleFirstTab);
  }, []);

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <DraftProductsProvider>
      <PrismicProvider
        linkResolver={linkResolver}
        internalLinkComponent={({ href, ...props }) => (
          <Link to={href} {...props} />
        )}
      >
        <PageSetup.Provider value={[pageSetup, setPageSetup]}>
          <Location>
            {({ location }) => {
              return (
                <Container
                  className={`page-type-${pageSetup.pageType}`}
                  height={height}
                >
                  <Normalize />
                  <GlobalStyles />
                  <App
                    location={location}
                    children={children}
                    pageSetup={pageSetup}
                    setPageSetup={setPageSetup}
                    height={height}
                  />
                </Container>
              );
            }}
          </Location>
        </PageSetup.Provider>
      </PrismicProvider>
    </DraftProductsProvider>
  );
};

export default Layout;
