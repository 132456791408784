import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useLockBodyScroll, useToggle } from "react-use";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";

// Icons
import { BagIcon, MenuToggleIcon } from "../icons/menu-icons";

// Search
import SearchIndex from "../search/search-index";

// Hooks
import { useCollections } from "../hooks/useCollections";
import { useFooterLinks } from "../hooks/useFooterLinks";

// Components
import { AlertBar } from "../alert/alert-bar";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 0 10px;

  & nav {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    height: 50px;
  }

  z-index: 200;
  line-height: 50px;

  & h1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0;

    & .small {
      font-size: 7px;
      padding: 0 6px;
      letter-spacing: 0.02em;
    }
  }

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    & li {
      &.menu-icon {
        padding: 0 15px 0 0;
      }

      &.cart-icon {
        padding: 0 0 0 15px;
      }

      & a,
      & button {
        display: flex;
        font-size: 15px;
        transition: 250ms color ease;

        &.current {
          color: #e9ccb2;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #e9ccb2;
          }
        }
      }

      & button {
        display: block;

        & svg {
          display: block;
          /* margin: 10px 0 0 0; */

          width: 13px;
          height: auto;
        }
      }

      &.cart-icon {
        margin: 0 0 0 auto;

        & svg {
          width: 13px;
          height: auto;
        }
      }
    }
  }

  @media (max-width: 960px) {
    & nav {
      height: 35px;

      & h1 {
        line-height: 35px;
      }
    }

    line-height: 35px;
  }
`;

const MobileMenuContainer = styled.div`
  /* padding: 10px; */
  padding: 7px 10px 10px 10px;

  & .mobile-menu-navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    height: 52px;

    /* padding: 10px 0 0 0; */

    & button {
      /* line-height: 30px; */

      &.menu-toggle-is-open {
        & svg {
          display: block;
          // margin: 10px 0 0 0;

          transform: rotate(45deg);
        }
      }
    }

    & svg {
      &.search-icon-svg {
        display: block;
        margin: 0;
        padding: 2px 0 0 0;

        &.desktop {
          display: none;
        }
      }
    }
  }

  & .menu-container {
    min-height: calc(${props => props.height}px - 50px - 7px - 10px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & .collections-menu {
    margin: 0 0 25px 0;

    & li {
      & a {
        & .hover-arrow {
          display: none;
        }
      }
    }
  }

  & .collections-menu,
  & .main-menu {
    & li {
      & a {
        font-size: 16px;
        line-height: 24px;

        color: #0b646f;
      }
    }
  }

  & .footer-menu {
    & li {
      font-size: 11px;
      line-height: 18px;

      color: #0b646f;
    }
  }
`;

Modal.setAppElement("#___gatsby");

export const MobileMenu = ({
  location,
  pageSetup,
  setPageSetup,
  content,
  isAlertActive,
  isDesktop,
}) => {
  const collections = useCollections();
  const footer = useFooterLinks();

  // Window Height
  const height = use100vh();

  // Scroll lock
  const [locked, toggleLocked] = useToggle(false);
  useLockBodyScroll(locked);

  // Filters
  const [openFilter, setOpenFilter] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Search
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    // if (isMenuOpen) {
    setIsMenuOpen(false);
    // }
  }, [location]);

  return (
    <>
      <HeaderWrapper>
        <nav role="navigation" aria-label="Main">
          <ol>
            <li className="menu-icon">
              <button
                onClick={() => setIsMenuOpen(true)}
                aria-label="Open Menu"
              >
                <MenuToggleIcon />
              </button>
            </li>
          </ol>

          <Link to="/">
            <h1 className="logo uppercase">
              House
              <span className="small">of</span>
              Voltaire
            </h1>
          </Link>

          <ol>
            <li className="cart-icon">
              <Link aria-label="cart" to="/cart/" activeClassName="current">
                <BagIcon />
              </Link>
            </li>
          </ol>

          {isMenuOpen && (
            <RemoveScroll>
              <Modal
                isOpen={isMenuOpen}
                portalClassName={`ReactModalPortal-mobile-menu`}
                className="mobile-menu-modal"
                contentLabel={`Mobile Menu`}
                shouldFocusAfterRender={true}
              >
                <MobileMenuContainer height={height}>
                  <div className="mobile-menu-navigation">
                    <button
                      onClick={() => setIsMenuOpen(false)}
                      className="menu-toggle-is-open"
                    >
                      <MenuToggleIcon fill={`#0B646F`} />
                    </button>

                    <SearchIndex
                      isSearchOpen={isSearchOpen}
                      setIsSearchOpen={setIsSearchOpen}
                      location={location}
                      fill={`#0B646F`}
                      isDesktop={isDesktop}
                    />
                  </div>

                  <div className="menu-container">
                    <div className="top-row">
                      <ol className="collections-menu">{collections}</ol>
                      <ol className="main-menu">{content}</ol>
                    </div>

                    <div className="bottom-row">
                      <ol className="footer-menu">
                        {footer}
                        <li>
                          <Link
                            to={`/mailing-list/`}
                            state={{ from: `website` }}
                          >
                            Join our mailing list
                          </Link>
                        </li>
                      </ol>
                    </div>
                  </div>
                </MobileMenuContainer>
              </Modal>
            </RemoveScroll>
          )}
        </nav>

        {isAlertActive && <AlertBar />}
      </HeaderWrapper>
    </>
  );
};
