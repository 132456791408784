import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useLockBodyScroll, useToggle } from "react-use";

// Icons
import { BagIcon } from "../icons/menu-icons";

// Search
import SearchIndex from "../search/search-index";

// Components
import { FilterWrapper } from "../filter/filter-wrapper";
import { AlertBar } from "../alert/alert-bar";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  & nav {
    display: grid;
    grid-template-columns: 300px 1fr 300px;
    padding: 0 25px;
  }

  z-index: 200;
  line-height: 50px;

  & h1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    /* font-size: 22px; */
    font-size: 18px;
    line-height: 50px;
    letter-spacing: 0;

    & .small {
      font-size: 10px;
      padding: 0 10px;
      letter-spacing: 0.02em;
    }
  }

  & a.logo {
    display: block;
    width: fit-content;
    margin: 0 auto;
  }

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &.main-menu {
      justify-content: flex-end;
    }

    & li {
      margin: 0 15px 0 0;

      & a,
      & button {
        /* font-size: 15px; */
        font-size: 13px;

        transition: 250ms color ease;

        &.current {
          color: #e9ccb2;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #e9ccb2;
          }
        }
      }

      & svg {
        & path {
          transition: 250ms fill ease;
        }

        &.current {
          & path {
            fill: #e9ccb2;
          }
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            & path {
              fill: #e9ccb2;
            }
          }
        }
      }

      &.cart-icon {
        margin: 0 10px 0 30px;
      }

      &.search-icon {
        margin: 0;

        &.is-open {
          width: 100%;
        }

        & .mobile {
          display: none;
        }
      }
    }
  }
`;

export const DesktopMenu = ({
  location,
  pageSetup,
  setPageSetup,
  content,
  isDesktop,
  isAlertActive,
}) => {
  // Scroll lock
  const [locked, toggleLocked] = useToggle(false);
  useLockBodyScroll(locked);

  // Filters
  const [openFilter, setOpenFilter] = useState(null);

  // Search
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    toggleLocked(false);
    setOpenFilter(null);
  }, [location]);

  return (
    <>
      <HeaderWrapper>
        <nav role="navigation" aria-label="Main">
          <ol>
            {(pageSetup.pageType === `shop` ||
              pageSetup.pageType === `collections` ||
              pageSetup.pageType === `artist`) && (
              <>
                <li>
                  <button
                    onClick={() => {
                      setOpenFilter(`collections`);
                      toggleLocked();
                    }}
                    className={`uppercase ${
                      openFilter === `collections` ? `active` : ``
                    }`}
                  >
                    → collections
                  </button>
                </li>

                <li>
                  <button
                    onClick={() => {
                      setOpenFilter(`filter`);
                      toggleLocked();
                    }}
                    className={`uppercase ${
                      openFilter === `filter` ? `active` : ``
                    }`}
                  >
                    → filter
                  </button>
                </li>
              </>
            )}
          </ol>

          <Link to="/" className="logo">
            <h1 className="logo uppercase">
              House
              <span className="small">of</span>
              Voltaire
            </h1>
          </Link>

          <ol className="main-menu">
            {!isSearchOpen && (
              <>
                {content}

                <li className="cart-icon">
                  <Link aria-label="cart" to="/cart/" activeClassName="current">
                    <BagIcon />
                  </Link>
                </li>
              </>
            )}

            <li className={`search-icon ${isSearchOpen ? `is-open` : ``}`}>
              <SearchIndex
                isSearchOpen={isSearchOpen}
                setIsSearchOpen={setIsSearchOpen}
                location={location}
                isDesktop={isDesktop}
              />
            </li>
          </ol>
        </nav>

        {isAlertActive && <AlertBar />}
      </HeaderWrapper>

      <FilterWrapper
        open={openFilter !== null}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        pageSetup={pageSetup}
        setPageSetup={setPageSetup}
        isDesktop={isDesktop}
        toggleLocked={toggleLocked}
        location={location}
      />
    </>
  );
};
