import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

const AlertBarContainer = styled.div`
  background-color: #f5eadf;
  width: 100%;

  & p {
    font-size: 9px;
    line-height: 30px;
    color: #0b646f;

    margin: 0;
  }

  @media (max-width: 960px) {
    width: 100vw;
    margin: 0 -10px;
    padding: 10px;

    & p {
      line-height: 15px;
    }
  }
`;

export const AlertBar = () => {
  const data = useStaticQuery(graphql`
    {
      prismicMenu {
        data {
          is_alert_active
          alert_text
          alert_link {
            url
          }
        }
      }
    }
  `);

  return (
    <AlertBarContainer>
      {data.prismicMenu.data.alert_link.url !== null ? (
        <a href={data.prismicMenu.data.alert_link.url}>
          <p className="center-text">{data.prismicMenu.data.alert_text}</p>
        </a>
      ) : (
        <p className="center-text">{data.prismicMenu.data.alert_text}</p>
      )}
    </AlertBarContainer>
  );
};
