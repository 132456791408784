import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import { useAsyncFn } from "react-use";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { PageSetup } from "../components/context/page-context";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Components
import { SinglePrismicProduct } from "../components/product/single-product";
import { Video } from "../components/video/video";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 70px 50px 0 50px;
  overflow-x: hidden;

  @media (max-width: 960px) {
    padding: 30px 30px 0 30px;
  }

  @media (max-width: 768px) {
    padding: 30px 10px 0 10px;

    & .content {
      padding: 0 10px;
    }
  }

  & .module {
    margin: 0 auto 100px auto;

    &:last-of-type {
      margin: 0 auto;
      padding: 0 0 50px 0;
    }

    @media (max-width: 768px) {
      margin: 0 auto 30px auto;

      &:last-of-type {
        margin: 0 auto;
        padding: 0 0 30px 0;
      }
    }
  }

  & .breadcrumbs {
    margin: 0 0 23px 0;

    & p {
      font-size: 12px;
      line-height: 28px;

      color: #0b646f;

      margin: 0;

      @media (max-width: 768px) {
        font-size: 8px;
        line-height: 28px;
      }
    }
  }

  & .page-title {
    margin: 0 0 38px 0;

    & h1 {
      /* font-size: 22px;
      line-height: 32px; */

      font-size: 18px;
      line-height: 26px;

      text-align: center;

      color: #0b646f;
    }
  }

  & .text-container {
    /* max-width: 975px; */
    max-width: 785px;

    & h2,
    & p {
      color: #0b646f;
    }

    & h2 {
      text-align: center;
      line-height: 36px;

      margin: 0 0 30px 0;
    }

    & p {
      font-size: 16px;
      line-height: 23px;

      /* font-size: 14px;
      line-height: 21px; */

      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }

      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 17px;
      }
    }
  }

  & .image-container,
  & .video-container {
    margin: 0 auto;
    max-width: 1100px;

    text-align: center;
  }

  & .related-products-container {
    padding: 50px 50px 70px 50px;
    margin: 0 -50px 0 -50px;
    overflow-x: hidden;

    background-color: #fff;

    @media (max-width: 960px) {
      padding: 30px;
      margin: 0 -30px 0 -30px;
    }

    @media (max-width: 768px) {
      /* padding: 30px 20px; */
      margin: 0 -20px;
    }

    & h2 {
      font-size: 12px;
      line-height: 15px;

      color: #0b646f;
      margin: 0 0 21px 0;
    }

    & .products-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 100px;

      @media (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 768px) {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
        grid-row-gap: unset;
      }
    }
  }
`;

const Event = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  useEffect(() => {
    setPageSetup({
      pageTitle: ``,
      pageType: `event`,
      filterArtists: [],
      selectedArtists: [],
      sortOption: null,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  const [productArtist, getProductArtist] = useAsyncFn(async id => {
    const response = await fetch(
      `/.netlify/functions/get-prismic-product-artist`,
      {
        method: "POST",
        body: JSON.stringify({
          id: id,
        }),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }
    );

    const result = await response.json();
    return result;
  }, []);

  const content = data.prismicEvent.data.body.map((content, index) => {
    if (content.slice_type === "image") {
      return (
        <div
          className="module single-image"
          key={`single_module_${content.id}`}
        >
          <div className="image-container">
            <GatsbyImage
              image={content.primary.image.gatsbyImageData}
              alt={
                content.primary.image.alt !== null
                  ? content.primary.image.alt
                  : ""
              }
              draggable="false"
              loading="lazy"
            />
          </div>
        </div>
      );
    }

    if (content.slice_type === "video") {
      return (
        <div
          className="module single-video"
          key={`single_module_${content.id}`}
        >
          <Video content={content} />
        </div>
      );
    }

    if (content.slice_type === "text") {
      return (
        <div
          className="module text-container news-gothic"
          key={`single_module_${content.id}`}
        >
          <PrismicRichText field={content.primary.text.richText} />
        </div>
      );
    }
  });

  const products = data.prismicEvent.data.related_products
    .filter(product => product.product !== null)
    .map((product, index) => (
      <SinglePrismicProduct
        product={product}
        index={index}
        key={`single_product_${index}_${product.id}`}
        getProductArtist={getProductArtist}
        productArtist={productArtist}
        isDesktop={isDesktop}
      />
    ));

  return (
    <>
      <PageSeo
        seoTitle={data.prismicEvent.data.seo_title}
        seoText={data.prismicEvent.data.seo_description}
        seoImage={data.prismicEvent.data.seo_image}
      />

      <Page>
        <section className="breadcrumbs">
          <p className="uppercase">
            <Link to={`/`}>HOME</Link> → <Link to={`/events/`}>Events</Link> →{" "}
            <Link to={data.prismicEvent.url}>
              {data.prismicEvent.data.title.text}
            </Link>
          </p>
        </section>

        <section className="page-title">
          <PrismicRichText field={data.prismicEvent.data.title.richText} />
        </section>

        <section className="content">{content}</section>

        {products.length >= 1 && (
          <section className="related-products-container">
            <h2>Related Products</h2>
            <div className="products-container">{products}</div>
          </section>
        )}
      </Page>
    </>
  );
};

export default withPrismicPreview(Event);

export const query = graphql`
  query EventQuery($uid: String) {
    prismicEvent(uid: { eq: $uid }) {
      url
      _previewable
      data {
        title {
          richText
          text
        }
        related_products {
          product {
            title
            handle
            id
            status
            admin_graphql_api_id
            image {
              src
            }
            variants {
              price
              admin_graphql_api_id
            }
          }
        }
        seo_title
        seo_description
        seo_image {
          url
        }
        body {
          ... on PrismicEventDataBodyImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData
                alt
              }
            }
          }
          ... on PrismicEventDataBodyVideo {
            id
            slice_type
            primary {
              video {
                id
                embed_url
                html
              }
            }
          }
          ... on PrismicEventDataBodyText {
            id
            slice_type
            primary {
              text {
                richText
              }
            }
          }
        }
      }
    }
  }
`;
