import React from "react";

export const ArrowRight = () => (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.52941 9.53027L0.46875 8.46961L3.93842 4.99994L0.46875 1.53027L1.52941 0.469613L6.05974 4.99994L1.52941 9.53027Z"
      fill="#0B646F"
    />
  </svg>
);

export const ArrowLeft = () => (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.47059 0.469727L6.53125 1.53039L3.06158 5.00006L6.53125 8.46973L5.47059 9.53039L0.940259 5.00006L5.47059 0.469727Z"
      fill="#0B646F"
    />
  </svg>
);

export const AddedToCartTriangle = () => (
  <svg
    width="25"
    height="13"
    viewBox="0 0 25 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.1672 0L24.3345 12.1672H0L12.1672 0Z" fill="#F5EADF" />
  </svg>
);
