import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

// Styles
import "../styles/plyr.css";

const VideoBannerContainer = styled.div`
  position: relative;
  z-index: 10;

  & a {
    display: block;
    cursor: pointer;
  }

  & iframe {
    user-select: none;
    pointer-events: none;
  }
`;

export const VideoBanner = ({ content }) => {
  const [player, setPlayer] = useState(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      setPlayer(
        new Plyr(`#video-banner${content.id}`, {
          autoplay: true,
          autopause: true,
          loop: { active: true },
          controls: ["mute"],
          hideControls: false,
          clickToPlay: false,
          playsinline: true,
          muted: true,
          volume: 1,
          vimeo: {
            autopause: false,
            // background: true,
            byline: false,
            controls: false,
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (player !== null) {
      player.on("playing", () => {
        setIsPlayerReady(true);
      });
    }
  }, [player]);

  if (content.primary.video.embed_url !== null) {
    return (
      <VideoBannerContainer>
        <div className="svg-text-container">
          {content.primary.svg_text.url !== null && (
            <GatsbyImage
              image={content.primary.svg_text.gatsbyImageData}
              alt={
                content.primary.svg_text.alt !== null
                  ? content.primary.svg_text.alt
                  : ""
              }
              draggable="false"
              loading="lazy"
            />
          )}
        </div>

        <div
          className="plyr__video-embed"
          id={`video-banner${content.id}`}
          dangerouslySetInnerHTML={{
            __html: content.primary.video.html,
          }}
        />
      </VideoBannerContainer>
    );
  } else {
    return null;
  }
};
