import { getMetafield } from "../utils/get-metafield";

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * https://ourcodeworld.com/articles/read/764/how-to-sort-alphabetically-an-array-of-objects-by-key-in-javascript
 *
 * @param {String} property Key of the object to sort.
 */
export const dynamicSort = property => {
  // if (property !== null) {
  if (property === "newest") {
    return function(a, b) {
      return b.updatedAt.localeCompare(a.updatedAt);
    };
  } else if (property === "ascending") {
    return function(a, b) {
      return (
        a.priceRangeV2.minVariantPrice.amount -
        b.priceRangeV2.minVariantPrice.amount
      );
    };
  } else if (property === "descending") {
    return function(a, b) {
      return (
        b.priceRangeV2.minVariantPrice.amount -
        a.priceRangeV2.minVariantPrice.amount
      );
    };
  } else if (property === "a-z") {
    return function(a, b) {
      const artistA = getMetafield(a, `artist_name`);
      const artistB = getMetafield(b, `artist_name`);

      if (artistA !== null && artistB !== null) {
        return artistA.localeCompare(artistB);
      }
    };
  }
  // }
  // else if (property === null) {
  //   return;
  // }
};
