exports.linkResolver = doc => {
  switch (doc.type) {
    case "homepage": {
      return `/`;
    }

    case "about": {
      return `/${doc.uid}/`;
    }

    case "event": {
      return `/events/${doc.uid}/`;
    }

    case "events": {
      return `/${doc.uid}/`;
    }

    case "shop": {
      return `/${doc.uid}/`;
    }

    case "contact": {
      return `/${doc.uid}/`;
    }

    case "delivery_and_returns": {
      return `/${doc.uid}/`;
    }

    case "privacy_policy": {
      return `/${doc.uid}/`;
    }

    case "terms_and_conditions": {
      return `/${doc.uid}/`;
    }

    case "page": {
      return `/${doc.uid}/`;
    }

    case "product": {
      return `/${doc.uid}/`;
    }

    case "project": {
      return `/project/${doc.uid}/`;
    }

    case "cart": {
      return `/${doc.uid}/`;
    }

    case "activity": {
      return `/activities/${doc.uid}/`;
    }

    case "join_us": {
      return `/${doc.uid}/`;
    }

    case "activity_category": {
      return `/activities/category/${doc.uid}/`;
    }

    case "search": {
      return `/${doc.uid}/`;
    }

    case "sitemap": {
      return `/${doc.uid}/`;
    }
  }
};
