import React, { useEffect, useRef, useState, useContext } from "react";
import { graphql, Link, navigate } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { use100vh } from "react-div-100vh";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { PageSetup } from "../components/context/page-context";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Icons
import { CloseIcon } from "../components/icons/slideshow-icons";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 0 30px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  min-height: ${props => props.height}px;

  & .top-navigation {
    display: grid;
    grid-template-columns: 300px 1fr 300px;
    padding: 0 25px;

    & h1 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      font-size: 18px;
      line-height: 50px;
      letter-spacing: 0;

      color: #0b646f;

      & .small {
        font-size: 10px;
        padding: 0 10px;
        letter-spacing: 0.02em;
      }
    }

    @media (max-width: 960px) {
      grid-template-columns: 20px 1fr 20px;
      padding: 0;

      & h1 {
        font-size: 14px;
        line-height: 30px;

        & .small {
          font-size: 6px;
          padding: 0 6px;
        }
      }
    }
  }
`;

const LightboxContainer = styled.div`
  background-color: #f5eadf;
  color: #0b646f;

  z-index: 100;
  height: 100%;
  width: 100%;

  & .lightbox-wrapper {
    width: 100%;
    position: relative;

    padding: 70px 0 100px 0;

    @media (max-width: 768px) {
      padding: 50px 0;
    }
  }

  & .title-container,
  & .text-container {
    max-width: 750px;
  }

  & .text-container {
    margin: 0 auto;
  }

  & .title-container {
    margin: 0 auto 40px auto;

    & h1 {
      /* font-size: 22px;
      line-height: 32px; */

      font-size: 18px;
      line-height: 26px;
    }

    @media (max-width: 768px) {
      margin: 0 0 3em 0;

      & h1 {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  & .text-container {
    & p,
    & li {
      font-size: 14px;
      line-height: 21px;

      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 17px;
      }
    }

    & strong {
      font-size: 12px;
      line-height: 21px;
      font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
        "Lucida Grande", sans-serif;

      display: block;
      margin: 3em 0 20px 0;

      @media (max-width: 768px) {
        font-size: 11px;
        line-height: 17px;

        margin: 3em 0 1em 0;
      }
    }

    & a {
      color: #c59a72;
    }

    & ol {
      list-style: decimal;
      padding: 0 0 0 20px;

      & li {
        list-style: decimal;
        padding: 0 0 0 35px;
        margin: 0 0 1em 0;

        @media (max-width: 768px) {
          padding: 0 0 0 15px;
        }
      }
    }
  }
`;

const CloseLightbox = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  z-index: 200;

  font-size: 1;
  line-height: 1;
  letter-spacing: 0;

  padding: 16px 25px;

  & svg {
    & path {
      transition: 250ms fill ease;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & svg {
        & path {
          fill: #dbaf96;
        }
      }
    }
  }

  @media (max-width: 960px) {
    padding: 8px 10px;
    height: 29px;

    & svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const TermsAndConditions = ({ data, location }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);
  const [backButtonAsLink, setBackButtonAsLink] = useState(true);
  const height = use100vh();

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  useEffect(() => {
    setPageSetup({
      pageTitle: ``,
      pageColor: `#f5eadf`,
      pageType: `delivery`,
      filterArtists: [],
      selectedArtists: [],
      sortOption: null,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  useEffect(() => {
    if (location !== undefined) {
      if (location.state !== undefined && location.state !== null) {
        if (location.state.from !== undefined && location.state.from !== null) {
          if (location.state.from === `website`) {
            setBackButtonAsLink(false);
          } else {
            setBackButtonAsLink(true);
          }
        }
      }
    }
  }, [location]);

  return (
    <>
      <PageSeo
        seoTitle={data.prismicTermsAndConditions.data.seo_title}
        seoText={data.prismicTermsAndConditions.data.seo_description}
        seoImage={data.prismicTermsAndConditions.data.seo_image}
      />

      <Page height={height}>
        <div className="top-navigation">
          <div className="spacer" />

          <Link to="/">
            <h1 className="logo uppercase">
              House
              <span className="small">of</span>
              Voltaire
            </h1>
          </Link>

          <CloseLightbox>
            {backButtonAsLink ? (
              <Link to={`/`}>
                <CloseIcon />
              </Link>
            ) : (
              <button onClick={() => navigate(-1)}>
                <CloseIcon />
              </button>
            )}
          </CloseLightbox>
        </div>

        <LightboxContainer>
          <div className="lightbox-wrapper">
            <div className="title-container center-text">
              <PrismicRichText
                field={data.prismicTermsAndConditions.data.title.richText}
              />
            </div>
            <div className="text-container news-gothic">
              <PrismicRichText
                field={data.prismicTermsAndConditions.data.text.richText}
              />
            </div>
          </div>
        </LightboxContainer>
      </Page>
    </>
  );
};

export default withPrismicPreview(TermsAndConditions);

export const query = graphql`
  {
    prismicTermsAndConditions {
      _previewable
      data {
        text {
          richText
        }
        title {
          richText
          text
        }
        seo_title
        seo_description
        seo_image {
          url
        }
      }
    }
  }
`;
